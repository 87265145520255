import React, { useState } from 'react'
import logo from './test.svg'
import { Form, Input, Button, Modal, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBCardText,
  MDBCardLink,
} from 'mdb-react-ui-kit'
import AxiosInstance from '../../service/AxiosInstance'
import ForgotPass from '../password/ForgotPass'
const Login = () => {
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const notify = () =>
    toast.error('Email OR Password Not Match!!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  const onFinish = (values) => {
    var urlencoded = new URLSearchParams()

    urlencoded.append('Email', values.username)
    urlencoded.append('Password', values.password)
    setLoading(true)

    AxiosInstance.post('/login', urlencoded).then(async (result) => {
      setLoading(false)
      // console.log('result are', result.data);
      if (result.data.data[0]) {
        localStorage.setItem('userdata', JSON.stringify(result.data.data))
        localStorage.setItem('token', result.data.token)

        // var isToken =await localStorage.getItem('token');
        const username = result.data.data?.[0]?.First_name + ' ' + result.data.data?.[0]?.Last_name
        toast.success(`Welcome ${username}`);
        setTimeout(() => {

          history.push('/dashboard')
        }, 2000)
        form.resetFields()
        window.location.reload()
      } else {
        notify()
      }
      // changeflag.setflag(!changeflag.flag);
    })
    // console.log('Success:', values);
    // form.resetFields();
  }

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  }

  const ForgotPassword = () => {
    const [visible, setVisible] = useState(false)


    const handleCancel = (errorInfo) => {

      setVisible(false)
    }
    return (
      <>
        <a className="" type="link" onClick={() => setVisible(true)}>
          Forgot Password?
        </a>

        <Modal
          title="Forgot Password"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={handleCancel}
          width={475}
          height={640}
          className="modal-back modal-title close-btn"
          footer={null}
        >
          <ForgotPass />

        </Modal>


      </>)



  }
  return (
    <div className="maindiv">
      <div className='hero-right'>
        <div className="main-model">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div>
            <MDBCardTitle className="titaltext">
              <h5 className='login-text left-head-title'>Login</h5>
              <p className='login-p'>Please enter your work email address</p>
              {/* <strong>PMS</strong> */}
            </MDBCardTitle>
            {/* <MDBCardSubTitle>Card subtitle</MDBCardSubTitle> */}
            {/* <MDBCardText>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </MDBCardText>
        <MDBCardLink href='#'>Card link</MDBCardLink>
        <MDBCardLink href='#'>Another link</MDBCardLink> */}
            <Form
              className='main-form'
              name="basic"
              labelCol={{
                span: 24,
              }}
              form={form}
              wrapperCol={{
                span: 24,
              }}
              //   initialValues={{
              //     remember:true,
              //   }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email!',
                  },
                ]}
              >
                <Input placeholder="Enter Email" className="fildewidth" />
              </Form.Item>

              <Form.Item
                className='password-input'
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter Password"
                  className="fildewidth w-100"
                />
              </Form.Item>


              <div className='login-input'>
                <Button
                  className="pms-same-btn"
                  htmlType="submit"
                 
                  id="log"
                  loading={loading}
                >
                  Login
                </Button>
                <ForgotPassword />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
