
import React, { useEffect, useRef, useState } from "react";
import { Form, Input } from "antd";
import AxiosInstance from "../../service/AxiosInstance";
import moment from "moment";

function WriteComment ({ User, TaskId, Type }) {


  const [form] = Form.useForm();
  const [comments, setComments] = useState([]);
  const [count2, setCount2] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [hoursLogsList, setHoursLogsList] = useState([]);
  const [empId, setEmpId] = useState([])
  const [empData, setEmpData] = useState([])

  useEffect(() => {
    const formData = new URLSearchParams();
    formData.append("Task_id", TaskId);
    AxiosInstance.post("auth/tasks/comments", formData).then(async (res) => {
      if (res.data.status === 1) {
        setComments(res.data.data);
      }
    });
    AxiosInstance.get(`auth/history/${TaskId}`).then(async (res) => {
      if (res.data.status == 1) {
        setHistoryList(res.data.data[0].History)
        res.data.data[0].History.map((ele) => {
          console.log(ele?.User_id, "harsh12")
          if (ele?.User_id !== undefined) {
            setEmpId(ele?.User_id)
          }
        })
        // setEmpId(res.data.data[0].History[0].User_id);
      }
    });
    AxiosInstance.get(`auth/tasks/tasktimer/${TaskId}`).then(async (res) => {
      if (res.data.status == 1) {
        setHoursLogsList(res.data.data);
      }
    });

  }, [count2]);

  useEffect(() => {
    empProfile();
  }, [empId])

  const empProfile = (async () => {
    AxiosInstance.get("auth/employees").then(async (res) => {
      res.data.data.filter((element) => {
        console.log(element, 52, empId);
        if (element._id == empId) {
          // let EmployeeData = {
          //   Profile_pic: element.Profile_pic,
          // }
          setEmpData(element)
          // empData.push(EmployeeData);
        }
      })
    })
  })
  console.log(empId, 40)
  console.log(empData, "harsh111")

  const onFinish = (values) => {
    if (values.comments.trim()) {
      const User = JSON.parse(localStorage.getItem('userdata'));
      const formData = new URLSearchParams();
      formData.append("User_id", User[0]._id);
      formData.append("First_name", User[0].First_name);
      formData.append("Last_name", User[0].Last_name);
      formData.append("Task_id", TaskId);
      formData.append("Type", Type);

      if (Type == "Project") {
        formData.append("Project_id", TaskId);
      }

      formData.append("Comment", values.comments);

      AxiosInstance.post("auth/tasks/comment/add", formData)
        .then((res) => {
          let formData1 = new URLSearchParams()
          console.log(res.data.data[0].at(-1).User_id, 11111);
          formData1.append("User_id", res.data.data[0].at(-1).User_id);
          formData1.append("Task_id", res.data.data[0].at(-1).Task_id);
          formData1.append("Updated_by", res.data.data[0].at(-1).First_name + ' ' + res.data.data[0].at(-1).Last_name)
          formData1.append(
            'Comments', res.data.data[0].at(-1).Comment
          )
          AxiosInstance.post('auth/history/add', formData1).then((res) => {
            if (res.data.status == 1) {
            }
          })
          setCount2(!count2);
          form.resetFields();
        })
        .catch((err) => console.log(err, "err"));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.sendMessage();
    }
  };
    return (
      <>
        <Form
          className="max-w"
          form={form}
          name="comments"
          // labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="add-cmt">
            <img
              src={
                User[0]._id && User[0].Profile_pic
                  ? User[0].Profile_pic
                  : "https://bootdey.com/img/Content/avatar/avatar1.png"
              }
              alt="profile"
              style={{
                width: "35px",
                height: "35px",
                objectFit: "contain",
                borderRadius: "50px",
                marginRight: "10px",
              }}
            />
            <div style={{ flex: "1" }}>
              <Form.Item name="comments">
                <Input.TextArea
                  placeholder="Add a comment..."
                  accessKey="A"
                  onPressEnter={(e) => {
                    if (e.altKey) {
                      form.submit();
                    }
                  }}
                />
              </Form.Item>
              <div className="tip-text">
                <p>
                  Pro-tip :{" "}
                  <span>
                    press <b>Alt + Enter </b> to comment
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Form>

      </>
    );
  }

  function Text({ content }) {
    const words = content.split(" ");
    const URL_REGEX = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
    return (
      <p>
        {words.map((word) => {
          return word.match(URL_REGEX) ? (
            <>
              <a href={word} target="_blank"> {word}  </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }


  function CommentList({ comments }) {
    return (
      <>
        {comments &&
          comments.map((item, number) => {
            let time = 0;
            let current = Date.now();
            let before = new Date(item.Create_at).getTime();

            //console.log('x> y',before>current);
            let difrence = current - before;
            const days = Math.floor(difrence / (24 * 60 * 60 * 1000));
            const hours = Math.floor(difrence / (60 * 60 * 1000));
            const minutes = Math.floor(difrence / (60 * 1000));
            const sec = Math.floor(difrence / 1000);
            if (days > 0) {
              time = `${days} days ago`;
            } else if (hours > 0) {
              time = `${hours} hours ago`;
            } else if (minutes === 0 || minutes === 1) {
              time = `Just now`;
            } else {
              time = `${minutes} minutes ago`;
            }
            return (
              <div className="add-cmt" style={{ marginTop: "15px" }}>
                <img
                  src={
                    item.User_id && item.User_id.Profile_pic
                      ? item.User_id.Profile_pic
                      : "https://bootdey.com/img/Content/avatar/avatar1.png"
                  }
                  alt="profile"
                  style={{
                    width: "35px",
                    height: "35px",
                    objectFit: "contain",
                    borderRadius: "50px",
                    marginRight: "10px",
                  }}
                />
                <div style={{ flex: "1" }}>
                  <div className="user-name-detils">
                    <p>
                      {" "}
                      {item.First_name} {item.Last_name}
                    </p>
                    <p> {`${time}`}</p>

                    <p className="label-comment">Comment</p>
                  </div>
                  <div className="user-cmt-detail">
                    <p style={{ margin: "0" }}>
                      {" "}
                      <><Text content={item.Comment} /></>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }


export default WriteComment;