import React from "react";
import AttendanceReport from "../components/Attendance/AttendanceReport";
import ManualAttendanceReport from "../components/Attendance/ManualAttendanceReport";
import AttendanceSummary from "../components/Attendance/AttendanceSummary";
import MonthWiseAttendanceReport from "../components/Attendance/MonthWiseAttendanceReport";
import DueDashboard from "../components/DueDashboard/DueDashboard";
import EmployeeTracker from "../components/EmployeeTracker/EmployeeTracker";
import EmployeePresentReport from "../components/Employee_master/EmployeePresentReport";
import Location from "../components/Location/Location";
import Attendance from "../components/Me/Attendance/Attendance";
import Me from "../components/Me/Me";
import Overtime from "../components/Me/Overtime/Overtime";
import MilestoneReport from "../components/Projects/milestone/MilestoneReport";
import SprintReport from "../components/Projects/sprint/SprintReport";
import ProjectStatusReport from "../components/ProjectStatusReport/ProjectStatusReport";
import TaskTimerReport from "../components/Tasks/TaskTimerReport";
import { Vertical } from "../components/Vertical/Vertical";
import KRA from "../components/employeeKra/KRA";
import TLKRA from "../components/employeeKra/TLKra";

import View from "../components/Tasks/View.js";
import { ProjectStatus } from "../components/Options";
import TaskTable from "../components/Eventdashboard/MyTeam/MyTeamTask";
import { Loading } from "../components/Common/Loader";
import KraForm from "../components/hrDashboard/Kra";
import TLViewKra from "../components/employeeKra/TLViewKra";
//import TeamLeave from "../components/Eventdashboard/teamLeave/TeamLeave";

let userData = localStorage.getItem("userdata")
  ? localStorage.getItem("userdata")
  : undefined;
let Position_Id = userData ? JSON.parse(userData)[0].Position_id : undefined;

const Dashboard = React.lazy(() =>
  import("../components/Eventdashboard/Eventdashboard")
);
const Mydashboard = React.lazy(() =>
  import("../components/Eventdashboard/Mydashboard")
);
const EmployeeReport = React.lazy(() =>
  import("../components/EmployeeReport/EmployeeReport")
);
const EmployeeChart = React.lazy(() =>
  import("../components/EmployeeChart/EmployeeChart")
);

const TaskProgress = React.lazy(() =>
  import("../components/TaskProgress/TaskProgress")
);
const MyAttendance = React.lazy(() =>
  import("../components/Attendance/MyAttendance")
);
const EmployeePresentList = React.lazy(() =>
  import("../components/Attendance/EmployeePresentList")
);

const TeamLeave = React.lazy(() =>
  import("../components/Eventdashboard/teamLeave/TeamLeave")
);
const Pending = React.lazy(() =>
  import("../components/Eventdashboard/teamLeave/Pending")
);
const InterviewForm = React.lazy(() =>
  import("../components/forms/interviewForm/InterviewForm")
);
const HrDashboard = React.lazy(() =>
  import("../components/hrDashboard/HrDashboard")
);
// leaves
const Leaves = React.lazy(() => import("../components/Me/LeaveModule/List"));
const LeavesReport = React.lazy(() =>
  import("../components/Me/LeaveModule/ListReport")
);

const ForgotPass = React.lazy(() =>
  import("../components/password/ForgotPass")
);

// leaves
const Tickets = React.lazy(() => import("../components/Ticket/List"));
const TicketsReport = React.lazy(() =>
  import("../components/Ticket/ListReport")
);
//
//#region project
const ProjectAdd = React.lazy(() => import("../components/Projects/Add"));
const ProjectList = React.lazy(() => import("../components/Projects/List"));
const ProjectDetail = React.lazy(() => import("../components/Projects/Detail"));
const ProjectTaskDetail = React.lazy(() =>
  import("../components/Projects/TaskDetails")
);
const projectMember = React.lazy(() =>
  import("../components/Projects/Members")
);
const Milestone = React.lazy(() =>
  import("../components/Projects/milestone/Milestone")
);
const Sprint = React.lazy(() => import("../components/Projects/sprint/Sprint"));

//#endregion project

const DirectoryList = React.lazy(() =>
  import("../components/Directory/DirectoryList")
);

const ProfileDetails = React.lazy(() =>
  import("../components/Profile/ProfileDetails")
);

//#region Employee
const EmployeeList = React.lazy(() =>
  import("../components/Employee_master/EmployeeList")
);
const EmployeeDetail = React.lazy(() =>
  import("../components/Employee_master/EmployeeDetail")
);
//#endregion

//#region Task
const TaskList = React.lazy(() => import("../components/Tasks/List"));
const ProjectTaskList = React.lazy(() =>
  import("../components/Projects/TaskList")
);
const TaskView = React.lazy(() => import("../components/Tasks/View.js"));

const BugList = React.lazy(() => import("../components/Tasks/Bug/BugLIst"));
//#endregion

//#region master position
const Position = React.lazy(() => import("../components/master/Position"));
const Roles_Permission = React.lazy(() => import("../components/master/rolesPermission/RolesPermission"));
const addRolespermission = React.lazy(() => import("../components/master/rolesPermission/AddRolespermission"));
const ViewRolesPermission = React.lazy(() => import("../components/master/rolesPermission/ViewRolesPermission"));
const EditrolesPermission = React.lazy(() => import("../components/master/rolesPermission/EditrolesPermission"));
const ClientRequirements = React.lazy(() =>
  import("../components/master/ClientRequirements")
);
const TechStack = React.lazy(() => import("../components/master/TechStack"));
const Department = React.lazy(() => import("../components/master/Department"));
const TicketDepartment = React.lazy(() =>
  import("../components/master/TicketDepartment")
);
const Holidays = React.lazy(() =>
  import("../components/master/Holidays/Holidays")
);
//#endregion

//#region Report
const Report = React.lazy(() => import("../components/Report"));
//#endregion

//eventdashboard
// const Eventdashboard = React.lazy(() => import('../components/Eventdashboard/Eventdashboard'))

// viewall
const HolidaysList = React.lazy(() =>
  import("../components/HolidaysList/HolidaysList")
);

export const Routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/interviewform",
    name: "interviewform",
    component: InterviewForm,
  },
  {
    path: "/my-dashboard",
    name: "mydashboard",
    component: Mydashboard,
  },
  {
    path: "/hr-dashboard",
    name: "hrdashboard",
    component: HrDashboard,
  },
  {
    path: "/employeereport",
    name: "employeereport",
    component: EmployeeReport,
  },
  {
    path: "/project/employeechart/:id",
    name: "employeechart",
    component: EmployeeChart,
  },
  {
    path: "/taskprogress",
    name: "taskprogress",
    component: TaskProgress,
  },
  {
    path: "/myattendance",
    name: "myattendance",
    component: MyAttendance,
  },
  {
    path: "/employeepresentlist",
    name: "employeepresentlist",
    component: EmployeePresentList,
  },
  {
    path: "/employeeWorkingPlan",
    name: "employeeworkingplan",
    component: EmployeeReport,
  },
  {
    path: "/teamleave",
    name: "teamleave",
    component: TeamLeave,
  },
  {
    path: "/pending",
    name: "pending",
    component: Pending,
  },
  //#region project routes
  {
    path: "/",
    exact: true,
  },
  {
    path: "/PMS",
    name: "PMS",
    component: ProjectStatus,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileDetails,
  },

  {
    path: "/projects",
    name: "projectList",
    component: ProjectList,
  },
  {
    path: "/project/tasks/:id",
    name: "projectList",
    component: ProjectTaskList,
    sidebar: false,
  },
  {
    path: "/project/member/:id",
    name: "projectMember",
    component: projectMember,
  },
  {
    path: "/project/sprint/:id",
    name: "Sprint",
    component: Sprint,
  },
  {
    path: "/project/milestone/:id",
    name: "Milestone",
    component: Milestone,
  },
  {
    path: "/project/task/:id/:projectid",
    name: "projectTask",
    component: ProjectTaskDetail,
  },
  {
    path: "/projects/add",
    name: "projectAdd",
    component: ProjectAdd,
  },
  {
    path: "/project/view/:id",
    name: "projectView",
    component: ProjectDetail,
  },

  //#endregion project routes

  //#region task routes
  {
    exact: true,
    path: "/directory",
    name: "employees",
    component: DirectoryList,
  },
  {
    exact: true,
    path: "/report/attendance",
    name: "attendanace",
    component: AttendanceReport,
  },
  {
    exact: true,
    path: "/attendance",
    name: "attendanace",
    component: Attendance,
  },
  {
    exact: true,
    path: "/attendanceSummary",
    name: "attendanceSummary",
    component: AttendanceSummary,
  },
  {
    exact: true,
    path: "/report/month-wise-attendance",
    name: "attendanace",
    component: MonthWiseAttendanceReport,
  },
  {
    exact: true,
    path: "/report/attendance-summary",
    name: "attendance-report",
    component: AttendanceSummary,
  },
  {
    exact: true,
    path: "/report/kra",
    name: "kra",
    component: KraForm,
  },


  {
    exact: true,
    path: "/report/project-status",
    name: "attendanace",
    component: ProjectStatusReport,
  },

  {
    exact: true,
    path: "/employees",
    name: "employees",
    component: EmployeeList,
  },
  {
    exact: true,
    path: "/taskList",
    name: "taskList",
    component: TaskList,
  },
  {
    exact: true,
    path: "/task/:id",
    name: "taskView",
    component: TaskView,
  },
  {
    exact: true,
    path: "/task/:id/BugList",
    name: "BugList",
    component: BugList,
  },
  //#endregion
  {
    path: "/employeeDetail/:id",
    name: "employeeDetail",
    component: EmployeeDetail,
  },
  //#endregion employee routes

  //#region master position
  {
    path: "/positions",
    name: "position",
    component: Position,
  },
  {
    path: "/roles-permission",
    name: "Roles Permission",
    component: Roles_Permission,
  },
  {
    path: "/add-rolesPermission",
    name: "Roles Permission Add",
    component: addRolespermission,
  },
  {
    path: "/View-rolesPermission/:id",
    name: "Roles Permission View",
    component: ViewRolesPermission,
  },
  {
    path: "/editrolespermission/:id",
    name: "Edit RolesPermission",
    component: EditrolesPermission,
  },
  {
    path: "/requirements",
    name: "requirements",
    component: ClientRequirements,
  },
  {
    path: "/techStack",
    name: "techStack",
    component: TechStack,
  },
  {
    path: "/department",
    name: "department",
    component: Department,
  },
  {
    path: "/ticket-department",
    name: "ticketdepartment",
    component: TicketDepartment,
  },

  {
    path: "/holidays",
    name: "Holidays",
    component: Holidays,
  },
  //#endregion
  //#region report
  {
    PositionId: Position_Id,
    path: "/reportList",
    name: "reportList",
    component: Report,
    exact: true,
  },
  //region leaves
  {
    path: "/leaves",
    name: "leaves",
    component: Leaves,
  },
  {
    path: "/leaves-report",
    name: "leaves",
    component: LeavesReport,
  },

  //region tickes
  {
    path: "/tickets",
    name: "tickets",
    component: Tickets,
  },
  {
    path: "/tickets-report",
    name: "tickets",
    component: TicketsReport,
  },

  {
    path: "/forgot-password",
    name: "forgotpassword",
    component: ForgotPass,
  },
  // {
  //   path: '/event-dashboard',
  //   name: 'eventdashboard',
  //   component: Eventdashboard,
  // },
  {
    path: "/holidays-list",
    name: "HolidaysList",
    component: HolidaysList,
  },

  {
    path: "/me",
    name: "me",
    component: Me,
  },
  {
    path: "/milestone/report",
    name: "MilestoneReport",
    component: MilestoneReport,
  },
  {
    path: "/sprint/report",
    name: "SprintReport",
    component: SprintReport,
  },
  {
    path: "/tasks/tasktimer/report",
    name: "tasktimerReport",
    component: TaskTimerReport,
  },

  {
    path: "/location",
    name: "location",
    component: Location,
  },

  {
    path: "/vertical",
    name: "vertical",
    component: Vertical,
  },
  {
    path: "/overtime",
    name: "overtime",
    component: Overtime,
  },
  {
    path: "/employeePresentReport",
    name: "employeePresentReport",
    component: EmployeePresentReport,
  },
  {
    path: "/dueDashboard",
    name: "dueDashboard",
    component: DueDashboard,
  },
  {
    path: "/employee-tracker",
    name: "employee-tracker",
    component: EmployeeTracker,
  },
  {
    path: "/manualAttendanceRequestReport",
    name: "manualAttendanceRequestReport",
    component: ManualAttendanceReport,
  },
  {
    path: "/KRA",
    name: "KRA",
    component: KRA,
  },
  {
    path: "/TLKRA",
    name: "TLKRA",
    component: TLKRA,
  },
  {
    path: "/TLViewKra",
    name: "TLViewKra",
    component: TLViewKra,
  },



  //region end
];

export const ProjectRoutes = [];
