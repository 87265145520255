import React, { useEffect, useState } from 'react';
import AxiosInstance from '../service/AxiosInstance';
import { useHistory } from "react-router-dom";
const Permission = () => {
  let userData = JSON.parse(localStorage.getItem('userdata'));
  const [permissions, setPermissions] = useState({});
  let history = useHistory();
  //permission
  useEffect(() => {
    const data = new URLSearchParams();
    data.append("id", userData[0]?.Position_id)
    AxiosInstance.post('auth/position/details', data).then(async (res) => {
      
      // console.log(res, 'kkpermissions :::');
      if (res.data.status == 1) {
        setPermissions([res.data.data]);
      }
    }).catch(function (error) {

      // if (error.toJSON().status == 402) {

      //   localStorage.removeItem("token");
      //   localStorage.removeItem("userdata");
      //   history.push("/login");
      //   window.location.reload();

      // }

    }
    );
  }, []);
  // console.log(permissions,'assssssssssss');
  return permissions;
};

export default Permission;
