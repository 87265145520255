import React, { useEffect, useState } from 'react';
import AxiosInstance from '../../service/AxiosInstance';
import { useHistory } from "react-router-dom";

const Rolepermission = () => {
  let userData = JSON.parse(localStorage.getItem('userdata'));
  const [rolePermissions, setRolepermissions] = useState({});
  let history = useHistory();
  //permission

  const getRolPermissionList = () => {
    const data = new URLSearchParams();
    data.append("id", userData[0]?.Role_permission_id)
    AxiosInstance.post('auth/permission/details', data).then( (res) => {
      console.log(res.data , 14)
   
      if (res.data.status == 1) {
        setRolepermissions(res.data.data[0]);
      }
    })
    // .catch(function (error) {
    //   if (error) {

    //     localStorage.removeItem("token");
    //     localStorage.removeItem("userdata");
    //     history.push("/login");
    //     window.location.reload();

    //   }

    // })
  }
  useEffect(() => {
    getRolPermissionList()
  }, []);
  // console.log(permissions,'assssssssssss');
  return rolePermissions;
};

export default Rolepermission;
