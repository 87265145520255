import React, { useState } from 'react';
import { Typography } from 'antd'
import moment from 'moment'
import { MDBBadge, MDBIcon } from 'mdb-react-ui-kit';

//#endregion
export const EllipseText = (props) => {
  const { Text } = Typography
  const [ellipsis, setEllipsis] = useState(true)
  return (
    <>
      <Text
        style={
          ellipsis
            ? {
              width: 100,
            }
            : undefined
        }
        ellipsis={
          ellipsis
            ? {
              tooltip: props.text,
            }
            : false
        }
      >
        {props.text}
      </Text>
    </>
  )
}




//#endregion
export const taskPriorityBadge = (name) => {
  let priorityIcon = (
    <MDBIcon
      style={{ color: '#ffa900', marginLeft: '15px' }}
      fas
      icon="arrow-up"
    />
  );
  if (name == 'HIGH') {
    priorityIcon = (
      <MDBIcon
        style={{ color: 'red', marginLeft: '15px' }}
        fas
        icon="arrow-up"
      />
    );
  } else if (name == 'LOW') {
    priorityIcon = (
      <MDBIcon
        style={{ color: 'green', marginLeft: '15px' }}
        fas
        icon="arrow-down"
      />
    );
  }
  return (
    <>
      {priorityIcon}
    </>
  )
}

export const taskStatusBadge = (name) => {
  // status bg
  let statusBg = (
    <MDBBadge
    className='project-task-open'
      style={{ borderRadius: '7px', color: '#404040' }}
      color="light"
    >
      {name}
    </MDBBadge>
  );

  // let statusBg = 'shadow-0';
  if (name == 'IN PROGRESS') {
    statusBg = (
      <MDBBadge className='status-blue' style={{ borderRadius: '4px' }} >
        {name}
      </MDBBadge>
    )
  } else if (name== 'DEVELOPMENT DONE') {
    statusBg = (
      <MDBBadge className='status-blue' style={{ borderRadius: '4px' }} >
        {name}
      </MDBBadge>
    );
  } else if (name== 'REJECTED') {
    statusBg = (
      <MDBBadge style={{ borderRadius: '4px' }} >
        {name}
      </MDBBadge>
    );
  } else if (name== 'ON HOLD') {
    statusBg = (
      <MDBBadge style={{ borderRadius: '4px' }} >
        {name}
      </MDBBadge>
    );
  } else if (name== 'READY FOR QA') {
    statusBg = (
      <MDBBadge className='status-blue' style={{ borderRadius: '4px' }}>
        {name}
      </MDBBadge>
    );
  } else if (name== 'REOPEN') {
    statusBg = (
      <MDBBadge className='badge-light' style={{ borderRadius: '4px' }} >
        {name}
      </MDBBadge>
    );
  } else if (name== 'READY FOR UAT') {
    statusBg = (
      <MDBBadge className='status-green' style={{ borderRadius: '4px' }} color="success">
        {name}
      </MDBBadge>
    );
  } else if (name== 'CLOSE') {
    statusBg = (
      <MDBBadge className='status-green' style={{ borderRadius: '4px' }} color="success">
        {name}
      </MDBBadge>
    );
  }
  return (
    <>
      {statusBg}
    </>
  )
}

export const LeaveDates = ({ items }) => {
  const dateArray = items.Leave_Date_to_Date.split(',')
  const newStartDate = new Date(dateArray[0])
  const newEndDate = new Date(dateArray[1])
  const halfStart = items.Leave_half_from
  const halfTo = items.Leave_half_to
  let appliedDate = ''
  let days = ''
  if (
    moment(newStartDate, 'DD/MM/YYYY').format('MMM DD, YYYY') ===
    moment(newEndDate, 'DD/MM/YYYY').format('MMM DD, YYYY') &&
    halfStart === 'First Half' &&
    halfTo === 'Second Half'
  ) {
    appliedDate = moment(newStartDate, 'DD/MM/YYYY').format('MMM DD, YYYY')
    days = '1 Day'
  } else {
    appliedDate =
      moment(newStartDate, 'DD/MM/YYYY').format('MMM DD, YYYY') +
      ' - ' +
      moment(newEndDate, 'DD/MM/YYYY').format('MMM DD, YYYY')
    if (
      moment(newStartDate, 'DD/MM/YYYY').format('MMM DD, YYYY') ===
      moment(newEndDate, 'DD/MM/YYYY').format('MMM DD, YYYY')
    ) {
      appliedDate = moment(newStartDate, 'DD/MM/YYYY').format('MMM DD, YYYY')
      if (halfStart === 'Second Half') {
        days = '0.5 Day (Second Half)'
      } else {
        days = '0.5 Day (First Half)'
      }
    } else {
      let daysLeft =
        moment(newEndDate, 'DD/MM/YYYY').diff(
          moment(newStartDate, 'DD/MM/YYYY'),
          'days',
        ) + 1
      if (halfStart == 'First Half' && halfTo === 'Second Half') {
        days = daysLeft + ' Days'
      } else {
        if (halfStart === 'Second Half') {
          daysLeft -= 0.5
        }
        if (halfTo === 'First Half') {
          daysLeft -= 0.5
        }
        days = daysLeft + ' Days (' + halfStart + ' - ' + halfTo + ')'
      }
    }
  }

  return (
    <>
      {appliedDate} <span className="info">{days}</span>{' '}
    </>
  )
}