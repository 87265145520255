import React, { useEffect, useState } from 'react'
import { Space, Table, Tag } from 'antd';
import { Progress } from 'antd';

const dataSource = [
    {
        key: '1',
        Status: 'open',
        Count: 80,
        Percentage: <Progress percent={8} status="active" />
        ,
    },
    {
        key: '2',
        Status: 'close',
        Count: 855,
        Percentage: <Progress percent={25} status="active" />
        ,
    },
    {
        key: '3',
        Status: 'Ready For Qa',
        Count: 2,
        Percentage: <Progress percent={2} status="active" />
        ,
    },
    {
        key: '4',
        Status: 'In Progress',
        Count: 43,
        Percentage: <Progress percent={40} status="active" />
        ,
    },
    {
        key: '5',
        Status: 'Ready For UAT',
        Count: 95,
        Percentage: <Progress percent={90} status="active" />
        ,
    },
];

const columns = [
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
    },
    {
        title: 'Count',
        dataIndex: 'Count',
        key: 'Count',
    },
    {
        title: 'Percentage',
        dataIndex: 'Percentage',
        key: 'Percentage',
    },
];
const dataSource2 = [
    {
        key: '1',
        Status: 'open',
        high: 0,
        medium: 0,
        Low: 0,
        total: 0,
    },
    {
        key: '1',
        Status: 'open',
        high: 0,
        medium: 0,
        Low: 0,
        total: 0,
    }, {
        key: '1',
        Status: 'open',
        high: 0,
        medium: 0,
        Low: 0,
        total: 0,
    }, {
        key: '1',
        Status: 'open',
        high: 0,
        medium: 0,
        Low: 0,
        total: 0,
    }, {
        key: '1',
        Status: 'Total',
        high: 0,
        medium: 0,
        Low: 0,
        total: 0,
    }
];

const columns2 = [
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
    },
    {
        title: 'High',
        dataIndex: 'high',
        key: 'high',
    },
    {
        title: 'Medium',
        dataIndex: 'medium',
        key: 'medium',
    },
    {
        title: 'Low',
        dataIndex: 'Low',
        key: 'Low',
    },
    {
        title: 'total',
        dataIndex: 'total',
        key: 'total',
    },
];


//main component
const TaskProgress = (props) => {
    return (
        <>
            <div className="task-progress">
                <div className='left-progress'>
                    <p className='progress-left-head'>Issue Statistics: Enrich React Native App </p>
                    <Table className='progress-table' dataSource={dataSource} columns={columns} />
                </div>
                <div className='right-progress left-progress'>
                    <p className='progress-left-head'>Open Defects Only- Enrich React Native App</p>
                    <Table className='progress-table progress-table2' dataSource={dataSource2} columns={columns2} />
                </div>
            </div>
        </>
    )
}

export default TaskProgress
