import React, { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../service/AxiosInstance";
import ProjectBar from "../Projects/ProjectBar";
import TaskProgress from "../TaskProgress/TaskProgress";
import { Space, Table, Tag } from 'antd';
import ReactLoader from "../Common/ReactLoader";
import { Progress } from 'antd';
const EmployeeChart = () => {
  const params = useParams();
    const [taskAnalytics, setTaskAnalytics] = useState( {
        series: [0, 0, 0,],
        labels: ["Task", "Story", "Bugs"],
      });
      
  const option = {
    series: [44, 55, 13, 33, 10],
    labels: ["InProgress", "Development Done", "Reopen", "close", "Rejected"],
  };
  const option2 = {
    series: [44, 55, 13, 33, 10],
    labels: ["InProgress", "Development Done", "Reopen", "close", "Rejected"],
  };
  const [loading,setLoading] = useState(false);
  const [taskStatistics, setTaskStatistics] = useState([
    {
        key: '1',
        Status: 'open',
        Count: 0,
        Percentage: <Progress percent={0} status="active" />
        ,
    },
    {
        key: '2',
        Status: 'close',
        Count: 0,
        Percentage: <Progress percent={0} status="active" />
        ,
    },
    {
        key: '3',
        Status: 'Ready For Qa',
        Count: 0,
        Percentage: <Progress percent={0} status="active" />
        ,
    },
    {
        key: '4',
        Status: 'In Progress',
        Count: 0,
        Percentage: <Progress percent={0} status="active" />
        ,
    },
    {
        key: '5',
        Status: 'Ready For UAT',
        Count: 0,
        Percentage: <Progress percent={0} status="active" />
        ,
    },
]);
const columns = [
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
    },
    {
        title: 'Count',
        dataIndex: 'Count',
        key: 'Count',
    },
  
    {
        title: 'Percentage',
        dataIndex: 'Percentage',
        key: 'Percentage',
    },
    {
      title: 'Task',
      dataIndex: 'Task',
      key: 'Task',
  },
    {
      title: 'Bugs',
      dataIndex: 'Bugs',
      key: 'Bugs',
  },
  {
    title: 'Story',
    dataIndex: 'Story',
    key: 'Story',
},
   
];

  const taskListData = () => {
    setLoading(true)
    AxiosInstance.get(`auth/project/task/list-analytics/${params.id}`).then(
      async (res) => {
        if (res.data.status == 1) {
          setLoading(false)

            let result=res.data.data;
            const openCount= result.filter((task) => task.Status === "OPEN").length;
            const inProgressCount= result.filter((task) => task.Status === "IN PROGRESS").length;
            const developmentDoneCount= result.filter((task) => task.Status === "DEVELOPMENT DONE").length;
            const readyForQACount= result.filter((task) => task.Status === "READY FOR QA").length;
            const reOpenCount= result.filter((task) => task.Status === "REOPEN").length;
            const rejectedCount= result.filter((task) => task.Status === "Rejected").length;
            const onHoldCount= result.filter((task) => task.Status === "ON HOLD").length;
            const closeCount= result.filter((task) => task.Status === "CLOSE").length;
            const totaltask=result.length;
            const totalbugs=result.length;
            const totalstory=result.length;

            const totalTask=result.filter((task) => task.Issue_type === "Task").length;
            const totalBugs=result.filter((task) => task.Issue_type === "Bug").length;
            const totalStory=result.filter((task) => task.Issue_type === "Story").length;

            const openTaskCount= result.filter((task) => task.Status === "OPEN"&&task.Issue_type === "Task").length;
            const openBugsCount= result.filter((task) => task.Status === "OPEN"&&task.Issue_type === "Bug").length;
            const openStoryCount= result.filter((task) => task.Status === "OPEN"&&task.Issue_type === "Story").length;

            const inProgressTaskCount= result.filter((task) => task.Status === "IN PROGRESS" &&task.Issue_type === "Task").length;
            const inProgressBugsCount= result.filter((task) => task.Status === "IN PROGRESS" &&task.Issue_type === "Bug").length;
            const inProgressStoryCount= result.filter((task) => task.Status === "IN PROGRESS"&&task.Issue_type === "Story").length;

            const developmentDoneTaskCount= result.filter((task) => task.Status === "DEVELOPMENT DONE" &&task.Issue_type === "Task").length;
            const developmentDoneBugsCount= result.filter((task) => task.Status === "DEVELOPMENT DONE" &&task.Issue_type === "Bug").length;
            const developmentDoneStoryCount= result.filter((task) => task.Status === "DEVELOPMENT DONE"&&task.Issue_type === "Story").length;

            const readyForQATaskCount= result.filter((task) => task.Status === "READY FOR QA" &&task.Issue_type === "Task").length;
            const readyForQABugsCount= result.filter((task) => task.Status === "READY FOR QA" &&task.Issue_type === "Bug").length;
            const readyForQAStoryCount= result.filter((task) => task.Status === "READY FOR QA"&&task.Issue_type === "Story").length;

            const reOpenTaskCount= result.filter((task) => task.Status === "REOPEN" &&task.Issue_type === "Task").length;
            const reOpenBugsCount= result.filter((task) => task.Status === "REOPEN" &&task.Issue_type === "Bug").length;
            const reOpenStoryCount= result.filter((task) => task.Status === "REOPEN"&&task.Issue_type === "Story").length;

            const rejectedTaskCount= result.filter((task) => task.Status === "Rejected" &&task.Issue_type === "Task").length;
            const rejectedBugsCount= result.filter((task) => task.Status === "Rejected" &&task.Issue_type === "Bug").length;
            const rejectedStoryCount= result.filter((task) => task.Status === "Rejected"&&task.Issue_type === "Story").length;

            const onHoldTaskCount= result.filter((task) => task.Status === "ON HOLD" &&task.Issue_type === "Task" ).length;
            const onHoldBugsCount= result.filter((task) => task.Status === "ON HOLD" &&task.Issue_type === "Bug").length;
            const onHoldStoryCount= result.filter((task) => task.Status === "ON HOLD"&&task.Issue_type === "Story").length;

            const closeTaskCount= result.filter((task) => task.Status === "CLOSE" &&task.Issue_type === "Task").length;
            const closeBugsCount= result.filter((task) => task.Status === "CLOSE" &&task.Issue_type === "Bug").length;
            const closeStoryCount= result.filter((task) => task.Status === "CLOSE"&&task.Issue_type === "Story").length;

            setTaskAnalytics( {
              series: [totalTask, totalStory, totalBugs,],
              labels: ["Task", "Story", "Bugs"],
            });

              setTaskStatistics([
                {
                    key: '1',
                    Status: 'Open',
                    Count: openCount,
                    Percentage: <Progress percent={(openCount/totaltask*100).toFixed()} status="active" />,
                    Bugs:openBugsCount,
                    Task:openTaskCount,
                    Story:openStoryCount
                },
                {
                    key: '2',
                    Status: 'InProgress',
                    Count: inProgressCount,
                    Percentage: <Progress percent={(inProgressCount/totaltask*100).toFixed()} status="active" />
                    ,
                    Bugs:inProgressBugsCount,
                    Task:inProgressTaskCount,
                    Story:inProgressStoryCount
                },
                {
                    key: '3',
                    Status: 'Development Done',
                    Count: developmentDoneCount,
                    Percentage: <Progress percent={(developmentDoneCount/totaltask*100).toFixed()} status="active" />
                    ,
                    Bugs:developmentDoneBugsCount,
                    Task:developmentDoneTaskCount,
                    Story:developmentDoneStoryCount
                },
                {
                    key: '4',
                    Status: 'Reopen',
                    Count: reOpenCount,
                    Percentage: <Progress percent={(reOpenCount/totaltask*100).toFixed()} status="active" />
                    ,
                    Bugs:reOpenBugsCount,
                    Task:reOpenTaskCount,
                    Story:reOpenStoryCount
                },
                {
                    key: '5',
                    Status: 'close',
                    Count: closeCount,
                    Percentage: <Progress percent={(closeCount/totaltask*100).toFixed()} status="active" />
                    ,
                    Bugs:closeBugsCount,
                    Task:closeTaskCount,
                    Story:closeStoryCount
                },
                {
                    key: '6',
                    Status: 'Rejected',
                    Count: rejectedCount,
                    Percentage: <Progress percent={(rejectedCount/totaltask*100).toFixed()} status="active" />
                    ,
                    Bugs:rejectedBugsCount,
                    Task:rejectedTaskCount,
                    Story:rejectedStoryCount
                },
                {
                    key: '7',
                    Status: 'ReadyForQA',
                    Count: readyForQACount,
                    Percentage: <Progress percent={(readyForQACount/totaltask*100).toFixed()} status="active" />
                    ,
                    Bugs:readyForQABugsCount,
                    Task:readyForQATaskCount,
                    Story:readyForQAStoryCount
                  
                },
                {
                    key: '8',
                    Status: 'On Hold',
                    Count: onHoldCount,
                    Percentage: <Progress percent={(onHoldCount/totaltask*100).toFixed()} status="active" />,
                    Bugs:onHoldBugsCount,
                    Task:onHoldTaskCount,
                    Story:onHoldStoryCount
                },

                
            ]);
            
         

        }
      }
    );
  };
  useEffect(() => {
    taskListData();
  }, []);
  return (
  <>
  {loading ?<ReactLoader loading={loading}>
      
      </ReactLoader>:
          <div className="sub-main-chart">
            <div className="left-side-chart">
              <div id="chart">
                <Chart
                  options={taskAnalytics}
                  series={taskAnalytics.series}
                  type="pie"
                  width={500}
                  height={320}
                />
              </div>
            </div>
            <div className="left-side-chart right-side-chart">
            <div className="task-progress">
                <div className='left-progress'>
                    <p className='progress-left-head'>Issue Statistics:  </p>
                    <Table className='progress-table' dataSource={taskStatistics} columns={columns} />
                </div>
                
            </div>
            </div>
          </div>
}
          </>    
  );
  return (
    <div>
      <div className="event-main">
        <ProjectBar />
        <div className="main-chart">
          <div className="chart-head">
            <p>Project Ratio</p>
          </div>
          <div className="sub-main-chart">
            <div className="left-side-chart">
              <div id="chart">
                <Chart
                  options={taskAnalytics}
                  series={taskAnalytics.series}
                  type="pie"
                  width={500}
                  height={320}
                />
              </div>
            </div>
            <div className="left-side-chart right-side-chart">
            <div className="task-progress">
                <div className='left-progress'>
                    <p className='progress-left-head'>Issue Statistics:  </p>
                    <Table className='progress-table' dataSource={taskStatistics} columns={columns} />
                </div>
                
            </div>
            </div>
          </div>
          {/* <TaskProgress /> */}
        </div>
      </div>
    </div>
 
  );
};

export default EmployeeChart;
