import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Modal, Form, Button } from "antd";
import { Link, useParams } from "react-router-dom";
import {
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBIcon,
} from "mdb-react-ui-kit";
import AxiosInstance from "../../service/AxiosInstance";
import Permission from "../Permission";
import AddTask from "../Tasks/Add";
import { ToastContainer, toast } from "react-toastify";

const ProjectBar = ({
  setCount,
  count,
  activeId,
  projectId,
  id,
  setList,
  Project_name,
}) => {
  const [projectDetail, setProjectDetail] = useState([]);
  const [taskAddIsShow, setTaskAddIsShow] = useState(false);
  const params = useParams();
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [rolePermissions, setRolepermissions] = useState({});


  let userData = JSON.parse(localStorage.getItem("userdata"));
  const getRolePermissionList = async () => {
    const data = new URLSearchParams();
    data.append("id", userData[0]?.Role_permission_id)
    await AxiosInstance.post('auth/permission/details', data).then((res) => {

      if (res.data.status == 1) {
        setRolepermissions(res.data.data[0]);
      }
    })
  }


  useEffect(() => {
    getRolePermissionList();
  }, [])
  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
  };
  const deleteData = async () => {
    const formdata = new URLSearchParams();
    formdata.append("id", id);
    await AxiosInstance.post("auth/tasks/delete", formdata).then((res) => {
      if (res.data.status == 1) {
        setList((pre) => pre + 1);
        setVisible(false);
      }
    });
  };
  useEffect(() => {
    //API
    const formData = new URLSearchParams();
    formData.append("id", projectId);
    AxiosInstance.post("auth/projects/detail", formData).then(async (res) => {
      if (res.data.status == 1) {
        setProjectDetail(res.data.data);
      }
    });
  }, [count]);

  return (
    <div>
      <ToastContainer />
      <MDBNavbarNav className="page-menu-nav page-menu-nav-pro">
        <div className="page-menu-nav2">
          <MDBNavbarItem>
            <Link to={`/project/view/${projectId}`} active>
              {" "}
              <MDBNavbarLink active aria-current="page" href="#">
                View
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          {
            rolePermissions?.Project?.All_Task == 1 ?
              <MDBNavbarItem>
                <Link to={`/project/tasks/${projectId}`} active>
                  {" "}
                  <MDBNavbarLink>
                    All Tasks
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              : ""}

          {
            rolePermissions?.Project?.View_Member == 1 ?
              <MDBNavbarItem>
                <Link to={`/project/member/${projectId}`}>
                  {" "}
                  <MDBNavbarLink>Members</MDBNavbarLink>
                </Link>
              </MDBNavbarItem>

              : ""
          }
          {
            rolePermissions?.Milestone_Project && Object.values(rolePermissions?.Milestone_Project).includes('1') == true ?
              <MDBNavbarItem>
                <Link to={`/project/milestone/${projectId}`}>
                  {" "}
                  <MDBNavbarLink>Milestone</MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              : ""
          }

          {rolePermissions?.Sprint_Project && Object.values(rolePermissions?.Sprint_Project).includes('1') == true ? (
            <MDBNavbarItem>
              <Link to={`/project/sprint/${projectId}`}>
                {" "}
                <MDBNavbarLink>Sprint</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
          ) : (
            ''
          )}

        </div>

        <div className="pro-add-task">
          {rolePermissions?.Project?.Create_Task == '1' ? (
            <Button
              className="btn-add-link add-task-btn"
              onClick={() => setTaskAddIsShow(true)}
            >
              <MDBIcon className="plus-btn" fas icon="plus" />
              <span
                style={{
                  paddingLeft: "15px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Create Task
              </span>
            </Button>
          ) : (
            ''
          )}

          {taskAddIsShow ? (
            <AddTask
              inSidebar={true}
              btnStyl={true}
              projectDetail={projectDetail}
              setFlage={setCount}
              setTaskAddIsShow={(a) => {
                setTaskAddIsShow(a);
              }}
            />
          ) : null}
          {rolePermissions?.Project?.Delete_Project == '1' ? (
            <Button className="btn-tresh-link" onClick={() => setVisible(true)}>
              <MDBIcon fas icon="trash" />
              <span style={{ paddingLeft: "15px", fontWeight: "600" }}>
                Delete Project
              </span>
            </Button>
          ) : (
            ''
          )}

          {/* Delete model */}
          <Modal
            title="Are you sure to delete this project?"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={handleCancel}
            width={340}
            bodyStyle={{
              marginLeft: "16%",
              paddingBottom: "6% !important",
              marginTop: "6% !important",
            }}
            footer={null}
          >
            <Button
              type="danger"
              onClick={deleteData}
              style={{
                marginRight: "10%",
                marginLeft: "5%",
                marginTop: "26px !important",
              }}
            >
              Delete
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Modal>
        </div>
      </MDBNavbarNav>
    </div>
  );
};

export default ProjectBar;
