import React, { useEffect, useState } from 'react';
import './App.css';
import '../src/assets/css/style.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import DefaultLayout from './components/DefaultLayout/DefaultLayout';

function App() {
  const [isLogedIn, setIsLogedIn] = useState('')
  // let isLogedIn = localStorage.getItem('token');
  useEffect(() => {
    setIsLogedIn(localStorage.getItem('token'))
  }, [])
  return (
    <>
      <HashRouter>
        <Switch>
          {!isLogedIn && <Route exact path="/login" render={() => <Login />} />}

          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
