import React, { useState } from 'react'
import { Table } from 'antd'
import AxiosInstance from '../../service/AxiosInstance'
import moment from 'moment'
import ManualRequestAttendance from "./ManualAttendanceRequest";
import { useEffect } from 'react'
import ReactLoader from "../Common/ReactLoader";

const LeaveAttendancePresent = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: 'green' }}></span>
        <p style={{ margin: '0px' }}>DP</p>
      </div>
    </div>
  )
}

const LeaveAttendanceAbsent = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: 'red' }}></span>
        <p style={{ margin: '0px' }}>ABS</p>
      </div>
    </div>
  )
}

const LeaveAttendanceWO = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: 'gray' }}></span>
        <p style={{ margin: '0px' }}>WO</p>
      </div>
    </div>
  )
}

const HolidayAttendance = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: 'blue' }}></span>
        <p style={{ margin: '0px' }}>HD</p>
      </div>
    </div>
  )
}
const PaidLeave = () => {
  return (
    <div>
      <div className="upper">
        <span style={{ backgroundColor: 'yellow' }}></span>
        <p style={{ margin: '0px' }}>PL</p>
      </div>
    </div>
  )
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Day',
    dataIndex: 'day',
    key: 'day',
  },
  {
    title: 'Shift Code',
    dataIndex: 'shiftcode',
    key: 'shiftcode',
  },
  {
    title: 'Shift inTime',
    dataIndex: 'shiftintime',
    key: 'shiftintime',
  },
  {
    title: 'Shift outTime',
    dataIndex: 'shiftouttime',
    key: 'shiftouttime',
  },
  {
    title: 'InTime',
    dataIndex: 'intime',
    key: 'intime',
  },
  {
    title: 'OutTime',
    dataIndex: 'outtime',
    key: 'outtime',
  },
  {
    title: 'First Half',
    dataIndex: 'firsthalf',
    key: 'firsthalf',
  },
  {
    title: 'Second Half',
    dataIndex: 'secondhalf',
    key: 'secondhalf',
  },
  {
    title: 'Portion',
    dataIndex: 'portion',
    key: 'portion',
  },
  {
    title: 'Tot. Hrs',
    dataIndex: 'tothrs',
    key: 'tothr',
  },
  // {
  //   title: 'InlateMark',
  //   dataIndex: 'inlatemark',
  //   key: 'inlatemark',
  // },
  // {
  //   title: 'OutlateMark',
  //   dataIndex: 'outlatemark',
  //   key: 'outlatemark',
  // },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
]

const AttendanceDetails = (props) => {
  const { year, month, employee_id } = props
  const [attendanceLogs, setAttendanceLogs] = useState([])
  const [totalAbsent, setTotalAbsent] = useState(0)
  const [workingDays, setWorkingDays] = useState(0)
  const [totalPresent, setTotalPresent] = useState(0)
  const [holiday, setHoliday] = useState(0)
  const [paidLeave, setPaidLeave] = useState(0)
  const [loading,setLoading] = useState(false);

  const [totalWO, setTotalWO] = useState(0)

  const findTotalHours = (checkin, checkout) => {
    var checkinTimeFormat = moment(checkin).format('HH:mm:ss')
    var checkoutTimeFormat = moment(checkout).format('HH:mm:ss')

    var startTime = moment(checkinTimeFormat, 'HH:mm:ss')
    var endTime = moment(checkoutTimeFormat, 'HH:mm:ss')

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime))

    // duration in hours
    var hours = parseInt(duration.asHours())

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60

    // duration in second
    var second = parseInt(duration.asSeconds()) % 60

    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (second < 10) {
      second = '0' + second
    }
    return hours + ':' + minutes + ':' + second
  }

  const checkFirstHalf = (item) => {

    

    if (item.present_first_half) {
      return <LeaveAttendancePresent />
    }else if(item.weekOff == true){
      return <LeaveAttendanceWO/>
    }else if(item.holiday == true){
      return <HolidayAttendance/>
    }else if(item.leave == true && item.present_first_half == false){
      return <PaidLeave/>
    }else{ 
      return <LeaveAttendanceAbsent />
    }
  }

  const checkSecondHalf = (item) => {
    
    if (item.present_second_half) {
      return <LeaveAttendancePresent />
    }else if(item.weekOff == true){
      return <LeaveAttendanceWO/>
    }else if(item.holiday == true){
      return <HolidayAttendance/>
    }else if(item.leave == true && item.present_second_half == false){
      return <PaidLeave/>
    }else{
      return <LeaveAttendanceAbsent />
    }
  }
  const checkHoliday = (item) => {
    
    if (item.holiday == true){
      return <HolidayAttendance/>
    }
  }

  const getDetails = (values) => {
    setTotalAbsent(0)
    setTotalPresent(0)
    
  if(props.month=='' || props.year=='') return false;
    const urlencoded = new URLSearchParams()
    urlencoded.append('month', props.month)
    urlencoded.append('year', props.year)
    urlencoded.append('employee_id', props.employee_id)
    setLoading(true)
    AxiosInstance.post('auth/faceLoginList', urlencoded).then(async (res) => {
      setLoading(false)
      let list1 = []
      const attendance=res.data.attendance;
      setTotalAbsent(attendance.absent)
      setTotalPresent(attendance.present)
      setHoliday(attendance.holiday)
      setWorkingDays(attendance.workingDays)
      setPaidLeave(attendance.paidLeave)

      list1 = res.data.data.map((item, index) => {
        return {
          ...item,
          no: '#',
          date: moment(item.date).format('DD-MM-YYYY'),
          day: moment(item.date).format('dddd').substring(0, 3),
          shiftintime: '10:00 AM',
          shiftouttime: '07:15 PM',
          intime: item.checkIn ? moment(item.checkIn).format('hh:mm A') : ' - ',
          outtime: item.checkOut
            ? moment(item.checkOut).format('hh:mm A')
            : ' - ',
          tothrs: item.checkOut
            ? findTotalHours(item.checkIn, item.checkOut)
            : '-',
          firsthalf:   checkHoliday(item)? checkHoliday(item): checkFirstHalf(item),
          secondhalf: checkHoliday(item)? checkHoliday(item) : checkSecondHalf(item),
          portion: item.present_count,
          action:<ManualRequestAttendance items={item} />
        }
      })
      let result = []

      for (
        let m = moment(`${year}-${month}-01`);
        m.isSameOrBefore(moment()) &&
        m.isSameOrBefore(moment(`${year}-${month}-1`).endOf('month'));
        m.add(1, 'days')
      ) {
        if (m.format('DD-MM-YYYY') !== moment().format('DD-MM-YYYY')) {
          let obj = {
            no: '#',
            date: m.format('DD-MM-YYYY'),
            day: m.format('dddd').substring(0, 3),
            shiftintime: '10:00 AM',
            shiftouttime: '07:15 PM',
            intime: ' - ',
            outtime: ' - ',
            tothrs: '-',
            firsthalf:
              m.format('dddd').substring(0, 3) == 'Sat' ||
              m.format('dddd').substring(0, 3) == 'Sun' ? (
                <LeaveAttendanceWO />
              ) : (
                <LeaveAttendanceAbsent />
              ),
            secondhalf: '-',
            portion: '',
          }

          let findFromList = list1.filter((o) => {
            return o.date == m.format('DD-MM-YYYY')
          })
          if (findFromList.length > 0) {
            obj = findFromList[0]
          }

          result.push(obj)
        }
      }
      setAttendanceLogs(result)
    })
  }

  useEffect(() => {
    console.log(props);
    getDetails()
  }, [props.month, props.year])


  return (
    <>
    {loading ?<ReactLoader loading={loading}>
          
          </ReactLoader>:
      <div className="attendance-table-sec">
        <div className="color-round boxes">
      
        <div className="box1">
            <div className="upper">
              <span style={{ backgroundColor: "#092E20" }}></span>
              <p style={{ margin: '0px' }}>TWD</p>
            </div>
            <p style={{ margin: '0px' }} className="atten-point">
              {workingDays}
            </p>
          </div>
          <div className="box1">
            <div className="upper">
              <span style={{ backgroundColor: 'blue' }}></span>
              <p style={{ margin: '0px' }}>HD</p>
            </div>
            <p style={{ margin: '0px' }} className="atten-point">
              {holiday}
            </p>
          </div>
          <div className="box1">
            <div className="upper">
              <span style={{ backgroundColor: 'green' }}></span>
              <p style={{ margin: '0px' }}>DP</p>
            </div>
            <p style={{ margin: '0px' }} className="atten-point">
              {totalPresent}
            </p>
          </div>
          <div className="box1">
            <div className="upper">
              <span style={{ backgroundColor: 'red' }}></span>
              <p style={{ margin: '0px' }}>ABS</p>
            </div>
            <p style={{ margin: '0px' }} className="atten-point">
              {totalAbsent}
            </p>
          </div>
          <div className="box1">
            <div className="upper">
              <span style={{ backgroundColor: 'yellow' }}></span>
              <p style={{ margin: '0px' }}>PL</p>
            </div>
            <p style={{ margin: '0px' }} className="atten-point">
              {paidLeave}
            </p>
          </div>
        
        </div>
        <div className="main-attendance-table">
          <Table
            dataSource={attendanceLogs}
            columns={columns}
            pagination={{ pageSize: 35 }}
          />
        </div>
      </div>}
    </>
  )
}

export default AttendanceDetails
