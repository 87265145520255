import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { Modal, Button, Form, Row, Col, Input, Dropdown, Menu } from "antd";

import AxiosInstance from "../../../service/AxiosInstance";
import AddOvertime from "./AddOvertime";
import moment from "moment";
import MeBar from "../MeBar";

const Overtime = () => {
  const [overtimeList, setOvertimeList] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [overtimeId, setOverTimeId] = useState("");
  const [approvalStatusComment, setApprovalStatusComment] = useState("");
  let user_data = JSON.parse(localStorage.getItem("userdata"));

  const [form] = Form.useForm();
  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Date",
          field: "Overtime_date",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Decription",
          field: "Description",
          width: 150,
          sort: "disabled",
        },

        // {
        //   label: "Reporting manager",
        //   field: "Reporting_manager",
        //   width: 150,
        //   sort: "disabled",
        // },
        {
          label: "Project name",
          field: "Project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Project manager",
          field: "Project_manager",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Approval status",
          field: "Approval_Status",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Approval Comment",
          field: "Approval_Status_Comment",
          width: 150,
          sort: "disabled",
        },

        {
          label: "Action",
          field: "Action",
          sort: "disabled",
          width: 30,
        },
      ],
      rows: overtimeList,
    });
  }, [overtimeList]);

  const getEmployeeOvertime = () => {
    const formData = new URLSearchParams();
    formData.append("Creater_id", user_data[0]._id);

    AxiosInstance.post("/overtime/getEmployeeOvertime", formData).then(
      (res) => {
        let list1 = res.data.data.map((item, index) => {
          return {
            icon: index + 1,
            Overtime_date: moment(item?.Overtime_date).format("DD/MM/YYYY"),
            Description: item?.Description,
            // Reporting_manager:
            //   item?.Reporting_manager_id?.First_name +
            //   " " +
            //   item?.Reporting_manager_id?.Last_name,

            Project_name: item?.Project_id?.Project_name,

            Project_manager:
              item?.Project_id?.Project_manager_id?.First_name +
              " " +
              item?.Project_id?.Project_manager_id?.Last_name,

            Approval_Status: item?.Approval_Status,

            Approval_Status_Comment: item?.Approval_Status_Comment
              ? item?.Approval_Status_Comment
              : " ",

            Action: actions(item),
            // Action: (
            //   <div style={{ display: "flex", justifyContent: "space-around" }}>
            //     {/* <MDBIcon
            //       fas
            //       icon="edit"
            //       style={{ color: "blue", cursor: "pointer" }}
            //       onClick={() => {
            //         setIsOpen(true);
            //         setOverTimeId(item?._id);
            //       }}
            //     />{" "}
            //     <MDBIcon
            //       fas
            //       icon="trash"
            //       style={{ color: "red", cursor: "pointer" }}
            //       onClick={() => {
            //         setOverTimeId(item?._id);
            //         setIsOpenDeleteModal(true);
            //       }}
            //     /> */}
            //     <Button
            //       style={{ color: "green" }}
            //       onClick={() => {

            //         approveStatus(item?._id);
            //       }}
            //     >
            //       Approve
            //     </Button>

            //     <Button
            //       style={{ color: "red" }}
            //       onClick={() => {
            //         setIsStatusOpen(true);
            //         setOverTimeId(item?._id);
            //       }}
            //     >
            //       Reject
            //     </Button>
            //   </div>
            // ),
          };
        });
        setOvertimeList(list1);
      }
    );
  };

  const handleClick = (items, key) => {
    if (key == "approve") {
      approveStatus(items?._id);
    } else if (key == "reject") {
      setIsStatusOpen(true);
      setOverTimeId(items?._id);
    }
  };

  const actionMenu = (items, listAction) => (
    <Menu items={listAction} onClick={(e) => handleClick(items, e.key)} />
  );
  const actions = (items) => {
    let listAction = [
      {
        key: "approve",
        label: "Approve",
        items: items,
      },
      {
        key: "reject",
        label: "Reject",
        items: items,
      },
    ];

    return (
      <>
        <Dropdown.Button
          overlay={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    );
  };

  const deletOvertime = (id) => {
    const urlencoded = new URLSearchParams();

    urlencoded.append("id", id);
    AxiosInstance.post("/overtime/deleteOvertime", urlencoded).then((res) => {
      setIsOpenDeleteModal(false);
      setOverTimeId("");
      getEmployeeOvertime();
    });
  };

  const handleCancel = (errorInfo) => {
    setIsOpenDeleteModal(false);
    setIsStatusOpen(false);
  };

  useEffect(() => {
    getEmployeeOvertime();
  }, []);

  const reloadList = () => {
    setIsOpen(false);
    getEmployeeOvertime();
  };

  const rejectStatus = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", overtimeId);
    urlencoded.append("Approval_Status_Comment", approvalStatusComment);

    AxiosInstance.post("/overtime/OvertimeStatusUpdate", urlencoded).then(
      (res) => {
        if (res.data.status == 200) {
          setApprovalStatusComment("");
          form.resetFields();
          setIsStatusOpen(false);
          reloadList();
        }
      }
    );
  };

  const approveStatus = (overtimeid) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", overtimeid);

    AxiosInstance.post("/overtime/OvertimeStatusUpdate", urlencoded).then(
      (res) => {
        if (res.data.status == 200) {
          reloadList();
        }
      }
    );
  };
  return (
    <>

      {/* Approval status modal */}
      <div className="demo">
        <MeBar />
        <MDBCard className="page-content">
          <div>

            <div className="create-project">
              <MDBCol size="md" className="col-example">
                <h3 className="tablHeader">Overtime</h3>
              </MDBCol>

              <MDBCol size="md" className="col-example float-right">
                <Button
                  className="pms-same-btn"
                  type="primary"
                  onClick={() => {
                    setIsOpen(true);
                    setOverTimeId("");
                  }}
                  style={{ float: "right" }}
                >
                  Add
                </Button>
                {isOpen ? (
                  <AddOvertime
                    overtimeId={overtimeId}
                    inSidebar={true}
                    btnStyl={true}
                    reloadList={reloadList}
                    setIsOpen={(a) => {
                      setIsOpen(a);
                    }}
                  />
                ) : null}
              </MDBCol>
            </div>
          </div>
          <div className="scrool-set">
            <MDBDataTableV5
              hover
              entriesOptions={[10, 20, 25]}
              entries={10}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>

        </MDBCard>
      </div>
    </>
  );
};

export default Overtime;
