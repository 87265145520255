import React, { useEffect, useState } from "react";
import { Button, Form, Radio, Modal, Menu, Dropdown, Input } from "antd";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import AxiosInstance from "../../service/AxiosInstance";
import moment from "moment";
const values = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const KraForm = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [datatable, setDatatable] = useState({});
  const [form] = Form.useForm();
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [flag, Setflag] = useState(false);
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const [employees, Setemployees] = useState([]);
  const [ratingId, setRatingId] = useState("");

  const showModal = (item) => {
    setId(item?._id);
    setIsModalOpen(true);
  };
  const showModal2 = (item) => {
    setId2(item?._id);
    setIsModalOpen2(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const onFinish = () => {
    const formdata = new URLSearchParams();
    // formdata.append("Q1", TLname);
    formdata.append("Q1", q2);
    formdata.append("Q2", q3);
    formdata.append("id", id);
    AxiosInstance.post("/auth/kra/team-leader", formdata).then((res) => {
      setIsModalOpen(false);
    });
  };
  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Code",
          field: "Employee_code",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Department",
          field: "department",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "Position",
          field: "Position",
          // sort: 'asc',
          width: 200,
        },
        {
          label: "Team Leader Name",
          field: "Team_leader_name",
          // sort: 'asc',
          width: 200,
        },

        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: employees,
    });
  }, [flag, employees]);

  const [select, setSelect] = useState({});
  const Rating = ({ value, id }) => {
    const formdata = new URLSearchParams();
    if (value == "TL/HOD") {
      formdata.append("value", value);
    }
    formdata.append("id", id);
    AxiosInstance.post("auth/kra/rating", formdata).then((res) => {
      if (res?.data?.data[0]?.Employee_rating) {
        setSelect(res?.data?.data[0]?.Employee_rating);
      } else {
        setSelect(res?.data?.data[0]?.Team_leader_rating);
      }
    });
  };

  useEffect(() => {
    if (ratingId) {
      Rating(ratingId);
    }
  }, [ratingId, values]);

  useEffect(() => {
    const formdata = new URLSearchParams();
    // formdata.append("Q1", TLname);
    // formdata.append("Role", "React/Node");
    AxiosInstance.post("auth/kra/list").then(async (result) => {
      if (result.data.data) {
        let employeesdeta = await result.data.data.map((item) => {
          return {
            name: `${item?.Employee_id?.First_name} ${item?.Employee_id?.Middle_name} ${item?.Employee_id?.Last_name}`,
            department: item?.Employee_id?.Role,
            Position: item?.Employee_id?.Position,
            Employee_code: item?.Employee_id?.Employee_code,
            Team_leader_name: `${item?.Team_leader_id?.First_name} ${item?.Team_leader_id?.Middle_name} ${item?.Team_leader_id?.Last_name}`,
            action: actions(item),
            // (
            //   <Link
            //     style={{ fontWeight: "500" }}
            //     onClick={() => showModal(item)}
            //   >

            //   </Link>
            // ),
          };
        });
        Setemployees(employeesdeta);
      }
    });
  }, [flag]);
  const handleClick = (items, value) => {
    if (value == "TL/HOD") {
      showModal(items);
    } else {
      showModal2(items);
    }
  };
  const actionMenu = (items) => {
    return (
      <>
        <Link
          style={{ fontWeight: "500" }}
          onClick={(e) => {
            let res = {
              id: items?.Employee_id?._id,
              value: "Employee",
            };
            setRatingId(res);
            Rating(res);
            handleClick(items, "Employee");
          }}
        >
          VIEW
        </Link>

        <Link
          style={{ fontWeight: "500" }}
          onClick={(e) => {
            let res = {
              id: items?.Employee_id?._id,
              value: "TL/HOD",
            };
            setRatingId(res);
            Rating(res);
            handleClick(items, "TL/HOD");
          }}
        >
          | TL/HOD
        </Link>
      </>
    );
  };

  const actions = (items) => {
    return (
      <>
        {/* <Dropdown.Button overlay={actionMenu(items)}></Dropdown.Button> */}
        <Link
          style={{ fontWeight: "500", marginRight: "10px" }}
          onClick={(e) => {
            let res = {
              id: items?.Employee_id?._id,
              value: "Employee",
            };
            setRatingId(res);
            Rating(res);
            handleClick(items, "Employee");
          }}
        >
          VIEW
        </Link>
        |
        <Link
          style={{ marginLeft: "10px", fontWeight: "500" }}
          onClick={(e) => {
            let res = {
              id: items?.Employee_id?._id,
              value: "TL/HOD",
            };
            setRatingId(res);
            Rating(res);
            handleClick(items, "TL/HOD");
          }}
        >
          TL/HOD
        </Link>
      </>
    );
  };
  return (
    <>
      <div className="demo">
        <div className="page-container">
          <div className="mx-0 create-project">
            <MDBCol size="md" className="col-example">
              <h3 className="tablHeader">Employee KRA</h3>
            </MDBCol>

            <MDBCol size="md" className="col-example float-right">
              <span className="btn-right right-emp-btn">
                <Modal
                  title="Rate review by TL/HOD"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  className="tl-kra-modal"
                >
                  <div>
                    <div className="questions">
                      <Form
                        form={form}
                        name="control-hooks"
                        layout="vertical"
                        autoComplete="off"
                      >
                        <h6 className="kra-modal-space">
                          {" "}
                          1.How well was His/her performance for all tasks in{" "}
                          {moment().subtract(1, "month").format("MMM")}{" "}
                          {moment().format("YYYY")} ?
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q1}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>

                        <h6 className="kra-modal-space">
                        2.How much was He/She capable to meet the deadlines? ?
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q2}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>

                        <h6 className="kra-modal-space">
                        3.Rate Him/her as a team player from scale of 1 to 10 based on your observation.
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q3}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>

                     
                      
                        <h6 className="kra-modal-space">
                        4.Rate Him/Her on overall performance on a scale of 1 to 10.
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q4}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>
                        <h6 className="kra-modal-space">
                        5.Rate His/Her behavior on a scale of 1 to 10. 
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q5}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Modal>
              </span>
            </MDBCol>
            <MDBCol size="md" className="col-example float-right">
              <span className="btn-right right-emp-btn">
                <Modal
                  title="Rate review by Employee"
                  open={isModalOpen2}
                  onOk={handleOk}
                  onCancel={handleCancel2}
                  className="tl-kra-modal"
                >
                  <div>
                    <div className="questions">
                      <Form
                        form={form}
                        name="control-hooks"
                        layout="vertical"
                        autoComplete="off"
                      >
                        <h6 className="kra-modal-space">
                          {" "}
                          1.How well you performed your task in{" "}
                          {moment().subtract(1, "month").format("MMM")}{" "}
                          {moment().format("YYYY")} ?
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q1}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>

                        <h6 className="kra-modal-space">
                          2.How capable were you to meet your deadlines?
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q2}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>

                        <h6 className="kra-modal-space">
                          3.Rate yourself as a team player from 1 to 10
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q3}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>

                        <h6 className="kra-modal-space">
                          4.Rate yourself in punctuality from 1 to 10?
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q4}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>

                        <h6 className="kra-modal-space">
                          5.Rate yourself on overall performance from 1 to 10?
                        </h6>
                        <Form.Item>
                          <Radio.Group
                            value={
                              Object.keys(select).length > 0
                                ? `${select?.Q5}`
                                : ""
                            }
                          >
                            {values.map((item, index) => {
                              return <Radio value={item}>{item}</Radio>;
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Modal>
              </span>
            </MDBCol>
          </div>
        </div>

        <div className="tasklisttbl page-container">
          <MDBDataTableV5
            hover
            entriesOptions={[20, 25, 30]}
            entries={20}
            pagesAmount={4}
            data={datatable}
            searchTop
            searchBottom={false}
          />
        </div>
      </div>
    </>
  );
};

export default KraForm;
