import axios from 'axios';
// const isToken = localStorage.getItem('client-login');
var isToken = localStorage.getItem('token');
var baseUrl = 'https://api.virtualheight.com/';
//var baseUrl = 'http://localhost:3000/';
var token = isToken ? isToken : '';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    auth: token,
  },
};

const AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: header.headers,
});

export default AxiosInstance;
