import React, { useEffect, useState } from "react";

import "antd/dist/antd.css";
import { useHistory, Link } from "react-router-dom";
import SelectEmpployee from "../Common/SelectEmpployee";
import moment from "moment";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import Permission from "../Permission";
import AxiosInstance from "../../service/AxiosInstance";




const MeBar = (props) => {


  const [rolePermissions, setRolepermissions] = useState({});

let userData = JSON.parse(localStorage.getItem("userdata"));
const getRolePermissionList = async () => {
  const data = new URLSearchParams();
  data.append("id", userData[0]?.Role_permission_id);
  await AxiosInstance.post("auth/permission/details", data).then((res) => {
    if (res.data.status == 1) {
      setRolepermissions(res.data.data[0]);
    }
  });
};

useEffect(() => {
  getRolePermissionList();
}, []);

  return (
    <>
      <MDBNavbarNav className="page-menu-nav">
        <MDBNavbarItem>
          <Link to="/me" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              My Leaves
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to="/myattendance" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
              Attendance
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        {/* <MDBNavbarItem>
          <Link to="/overtime">
            {" "}
            <MDBNavbarLink>Overtime</MDBNavbarLink>
          </Link>
        </MDBNavbarItem>{" "} */}
            <MDBNavbarItem>
            {rolePermissions?.Me?.Team_Leave == "1" ? (
              <Link to="/teamleave" active>
                {" "}
                <MDBNavbarLink active aria-current="page" href="#">
                  Team Leave
                </MDBNavbarLink>
              </Link>
        ) : (
          ""
        )}

       </MDBNavbarItem>
      </MDBNavbarNav>
    </>
  );
};

export default MeBar;
