import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
  Image,
  DatePicker,
} from "antd";
import PmsDatePicker from "../Me/LeaveModule/PmsDatePicker";
import { MDBIcon } from "mdb-react-ui-kit";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import Instance from "../../service/AxiosInstance";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from "moment";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import AxiosInstance from "../../service/AxiosInstance";

const Edit = ({ taskData, setCount, setVisible, visible }) => {
  //getting perticular project tasks list
  const params = useParams();
  const [tasksList, setTasksList] = useState([]);
  const [sprintdata, setSprintdata] = useState([]);
  const [assignDate, setAssignDate] = useState();



  const [form] = Form.useForm();
  // const [projectDetail, setProjectDetail] = useState();
  const [taskDatae, settaskDatae] = useState(taskData);
  const [textEditer, setTextEditer] = useState("");
  const [projectDetail, setProjectDetail] = useState();
  const [count2, setCount2] = useState(1);

  const [selectedMember, setSelectedMember] = useState("");
  const [textEditerCount, setTextEditerCount] = useState(0);
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const [reporterList, setReporterList] = useState([]);
  const [assigneesList, setAssigneesList] = useState([]);
  // const [selectedMemberID, setSelectedMemberID] = useState('');
  const [selectedMemberId, setSelectedMemberId] = useState("");

  const [urlArray, setUrlArray] = useState([]);
  const [olddocument, Setolddocument] = useState([""]);
  const [rolePermissions, setRolepermissions] = useState({});


  let userdata = JSON.parse(localStorage.getItem("userdata"));
  const getRolePermissionList = async () => {
    const data = new URLSearchParams();
    data.append("id", userData[0]?.Role_permission_id)
    await AxiosInstance.post('auth/permission/details', data).then((res) => {

      if (res.data.status == 1) {
        setRolepermissions(res.data.data[0]);
      }
    })
  }


  useEffect(() => {
    getRolePermissionList();
  }, [])

  let filterUrl;

  let config = {
    toolbarGroups: [
      // { name: "document", groups: ["mode", "document", "doctools"] },

      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      { name: 'links', groups: ['link'] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
      },
      { name: "styles", groups: ["Styles", "Format", "Font", "FontSize"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true,
    scayt_autoStartup: true,
  };
  useEffect(() => {
    if (taskDatae?.Project_id) {
      Instance.get(`auth/projecttasks/list/${taskDatae.Project_id}`).then((res) => {
        setTasksList(res.data.data);
      });
    }
  }, [taskDatae]);

  const getSprintData = () => {
    if (taskDatae?.Project_id) {
      const urlencoded = new URLSearchParams()
      urlencoded.append('Project_id', taskDatae?.Project_id)
      Instance.post('sprint/getAllsprint', urlencoded).then((res) => {
        setSprintdata(res.data.data);
      });
    }
  }

  useEffect(() => {
    getSprintData();
  }, [params?.projectid, taskDatae])
  // function chackDescription() {
  //   if (textEditer === '') setDescriptionAlert('Please Enter Description');
  //   if (textEditer !== '') setDescriptionAlert('');
  // }

  // useEffect(() => {
  //   if (visible === true) {
  //     if (taskData.Upload_file != '' && taskData.Upload_file != undefined) {
  //       setUrlArray(taskData.Upload_file);
  //     }
  //   }
  //   // setTextEditerCount(0);
  // }, [visible]);

  useEffect(() => {
    Setolddocument(taskData.Document);
  }, [taskData]);
  useEffect(() => {
    settaskDatae(taskData);
    setAssignDate(
      moment(
        taskData.Assigned_date2
          ? JSON.stringify(new Date(taskData.Assigned_date)).slice(1, 25)
          : ""
      )
    );
    form.resetFields();
    setTextEditer("");
    setUrlArray([]);
    setTextEditerCount(0);
  }, [visible, taskData]);
  // useEffect(()=>{
  //   // setTextEditer('');
  //   form.resetFields();
  // },[taskDatae])
  useEffect(() => {
    if (taskDatae?.Project_id) {
      const formData = new URLSearchParams();
      if (taskDatae.Project_id && taskDatae.Project_id !== "") {
        formData.append("id", taskDatae.Project_id);
        Instance.post("auth/projects/detail", formData).then((res) => {

          if (res.data.data) {
            form.resetFields();
            setProjectDetail(res.data.data);
          }
        });
      }
    }
  }, [taskDatae, count2]);

  // project data List
  useEffect(() => {
    if (projectDetail && taskDatae?.Project_id) {
      // let projectMember = projectDetail?.Project_member
      //   ? projectDetail?.Project_member.split(',')
      //   : '';
      //Employee
      Instance.get(`auth/projectemployeelist/${taskDatae?.Project_id}`).then(
        async (res) => {
          if (res.data.status == 1) {
            let EmList = res.data.data.map((member) => {
              // let EmID1 = res.data.data.filter((item) => item.First_name +' '+ item.Last_name == member);
              return {
                label: member.First_name + " " + member.Last_name,
                value: member.First_name + " " + member.Last_name,
                // value: EmID1[0]?._id,
              };
            });
            // setList(EmList);
            setAssigneesList(EmList);
          }
        }
      );
      let projectMan = projectDetail.Project_manager.split(",");
      setReporterList(projectMan);
    }
  }, [projectDetail]);

  // employee list
  useEffect(() => {
    if (taskDatae?.Project_id) {
      Instance.get(`auth/projectemployeelist/${taskDatae?.Project_id}`).then(
        async (res) => {
          if (res.data.status == 1) {
            let EmID = await res.data.data.filter(
              (item) => item.First_name + " " + item.Last_name == selectedMember
            );
            // setSelectedMemberID(EmID[0]?._id)
            setSelectedMemberId(EmID[0]?._id);
          }
        }
      );
    }
  }, [selectedMember, visible]);
  //submit

  function ImageDemo({ element, index }) {
    // let linkarray = element.split('.');
    let linkarray =
      element !== null && element !== undefined ? element?.split(".") : [];
    let exe = "jpg,jpeg,png";
    return (
      <>
        <div className="image">
          <div>
            <Image
              width={80}
              height={80}
              src={
                exe.includes(linkarray[linkarray.length - 1])
                  ? element
                  : "https://cdn1.iconfinder.com/data/icons/leto-files/64/leto_files-76-512.png"
              }
            />
          </div>
          <div>
            <DeleteOutlined onClick={() => deleteimage(index)} />
          </div>
        </div>
      </>
    );
  }
  console.log(textEditerCount, 248)

  const deleteimage = (index) => {
    let newarray = [...olddocument];
    newarray.splice(index, 1);
    Setolddocument([...newarray]);
  };

  const onFinish = (values) => {
    // alert('onfinish')
    let taskKey = `${values.projectShortName}`;
    let sptId;
    let sptName;
    if (values?.sprint == taskDatae?.Sprint_id?.Name) {
      sptName = taskDatae?.Sprint_id?.Name
      sptId = taskDatae?.Sprint_id?._id
    } else {
      sptName = JSON.parse(values.sprint)?.Name
      sptId = JSON.parse(values.sprint)?._id
    }
    console.log(textEditerCount, 267, textEditer);
    if (values) {
      const formData = new FormData();
      formData.append("Issue_type", values.IssueType);
      if (sptId) formData.append('Sprint_id', sptId ? sptId : '-');
      formData.append("Short_summary", values.shortSummary);
      formData.append("Task_duration", values.taskDuration);
      formData.append(
        "Description",
        textEditer ? textEditer : taskDatae.Description
      );
      for (let i = 0; i < olddocument?.length; i++) {
        formData.append("olddocument", olddocument[i]);
      }
      formData.append("Reporter", values.reporter ? values.reporter : "-");

      if (values.profile) {
        for (let i = 0; i < values.profile.fileList.length; i++) {
          formData.append(
            "demo_image",
            values.profile.fileList[i].originFileObj
          );
        }
      }
      formData.append(
        "Assigned_date",
        values.assignedDate && values.assignedDate !== null
          ? values.assignedDate
          : "-"
      );
      formData.append(
        "Due_date",
        values.dueDate && values.dueDate !== null ? values.dueDate : "-"
      );
      formData.append("Assignees", values.assignees);
      // formData.append(
      //   'Assignees',
      //   selectedMemberId && selectedMemberId !== ''
      //     ? selectedMemberId
      //     : taskData.Assignees
      // );
      formData.append("Priority", values.priority);
      formData.append("Project_short_name", values.projectShortName);
      // formData.append('Status', values.status);
      // formData.append('Employee_id', values.assignees);
      formData.append(
        "Employee_id",
        selectedMemberId && selectedMemberId !== ""
          ? selectedMemberId
          : taskDatae.Employee_id
      );
      // formData.append('Task_duration', values.taskDuration);

      formData.append("Linkissue_Taskkey", values.linkissue);

      formData.append("id", taskDatae._id);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      Instance.post("uplodeauth/tasks/edit", formData, config)
        .then(async (res) => {

          if (res.data.status == 1) {
            toast.success("Task updated Successfully");
            //  await setProjectDetail(res.data.data);
            setTextEditer("");
            setCount((pre) => pre + 1);
            setCount2((pre) => pre + 1);
            setVisible(false);
            // taskData=res.data.data
            settaskDatae(res.data.data);
            setTextEditerCount(0);
            form.resetFields();
            setVisible(false);
            form.resetFields();
            // create history
            const formData1 = new URLSearchParams();
            formData1.append("Task_id", taskDatae?._id);
            formData1.append(
              "Updated_by",
              userData[0]?.First_name + " " + userData[0]?.Last_name
            );
            formData1.append("Action", `Task`);
            Instance.post("auth/history/add", formData1).then((res) => {
              if (res.data.status == 1) {

              }
            });
          }
        })
        .catch((err) => console.log(err, "err"));
      // setTextEditer('');
      // setTextEditerCount(0)
      // setUrlArray('')
    }
  };


  const onFinishFailed = (errorInfo) => {

    // setTextEditer('');
  };



  const handleCancel = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // allFileDelete();
    form.resetFields();
    setVisible(false);
    setTextEditer("");
    setUrlArray([]);
    setTextEditerCount(0);
  };

  const onDescriptionChange = (evt) => {
    // setTextEditer(evt.editor.getData());
    setTextEditerCount(() => textEditerCount + 1);
  };
  console.log(textEditer, "textEditer")
  const content = () => {
    return textEditer
      ? textEditer
      : textEditerCount === 0
        ? taskDatae?.Description
        : "";
  };
  // upload file props
  const props = {
    className: "upload-list-inline",
    listType: "picture",
    maxCount: "50",
    multiple: "multiple",
    // openFileDialogOnClick: 'false',
    // defaultFileList: taskDatae?.Upload_file,

    action: "demo_image",
    // action: { url },

    //   onRemove(file) {
    //     if (file) {
    //       if (!file.url) {
    //         console.log(file, '=file');
    //         console.log(urlArray, 'urlArray onRemove');

    //         const myPromise = new Promise((resolve) => {
    //           resolve(urlArray.filter((item) => file.uid === item.uid));
    //         });

    //         myPromise.then((deleteFile) => {
    //           if (deleteFile[0].url != undefined)
    //             console.log(deleteFile[0].url, 'deleteFile[0].url res promiss');
    //           filterUrl = urlArray;
    //           setUrlArray(filterUrl.filter((item) => file.uid !== item.uid));
    //           const formData = new URLSearchParams();
    //           formData.append('image', deleteFile[0].url);

    //           Instance.post('auth/tasks/deleteImage', formData).then((result) => {
    //             console.log('delete result are', result.data);
    //           });
    //         });
    //       } else {
    //         // alert('delete');
    //         console.log(file, 'fileremove');
    //         const myPromise = new Promise((resolve) => {
    //           resolve(urlArray.filter((item) => file.uid === item.uid));
    //         });

    //         myPromise.then((deleteFile) => {
    //           // console.log(deleteFile[0].url, 'res promiss url');
    //           let removeUrl = urlArray;
    //           setUrlArray(removeUrl.filter((item) => file.uid !== item.uid));
    //           const formData = new URLSearchParams();
    //           formData.append('image', deleteFile[0].url);

    //           Instance.post('auth/tasks/deleteImage', formData).then((result) => {
    //             console.log('delete result are', result.data);
    //           });
    //         });
    //       }
    //     }
    //   },
    //   onChange({ file, fileList }) {
    //     // console.log(file, '=filedone');
    // console.log(taskData.Upload_file,'taskData.Upload_file in edite outonchange start ');

    // setCount((pre) => pre + 1);

    //     if (file.status == 'done') {
    //       console.log(fileList, '=fileList ');
    //       const formData = new FormData();
    //       formData.append('demo_image', file.originFileObj);
    //       const config = {
    //         headers: {
    //           'content-type': 'multipart/form-data',
    //         },
    //       };

    //       Instance.post('upload/uplodeimage', formData, config).then((result) => {
    //         console.log(' upload/uplodeimage result are', result.data);
    //         if (result.data.url && result.data.url !== '') {
    //           console.log(urlArray, 'urlArrayonChange');
    //           let data = urlArray;
    //           console.log(data,'data');
    //           data?.push({
    //             url: result.data.url,
    //             uid: file.uid,
    //             name: file.name,
    //           });
    //           setUrlArray(data);

    //           // alert('url')

    //           // console.log(url, 'Url');
    //           console.log(result.data.url, 'onChange result.data.url');
    // console.log(taskData.Upload_file,'taskData.Upload_file in edite outonchange end ');

    //           //       // fileList.thumbUrl = url;
    //         }
    //       });
    //     }
    //   },
  };
  // console.log(urlArray, 'urlArray');
  // console.log(taskData.Upload_file,'taskData.Upload_file in edite out');

  //#region all uploaded file delete fun
  // const allFileDelete = () => {
  //   console.log(urlArray.length >= 1, 'urlArray.length >= 1');
  //   console.log(urlArray.length, 'urlArray.length');

  //   const fileDeleteApi = (data) => {
  //     data.map((file) => {
  //       const formData = new URLSearchParams();
  //       console.log(file.url, 'url');
  //       formData.append('image', file.url);

  //       Instance.post('auth/tasks/deleteImage', formData).then((result) => {
  //         console.log( 'all file delete result are', result.data);
  //       });
  //     });
  //   };

  //   if (urlArray.length >= 1) {

  //     console.log(urlArray, 'urlArray allFileDelete');
  //     if (taskData.Upload_file.length >= 1) {
  //       console.log(taskData.Upload_file,'taskData.Upload_file in promess all file delete');

  //       // alert('taskData.Upload_file available');
  //       new Promise((resolve) => {
  //         resolve(
  //         //  urlArray.filter((item) => taskData.Upload_file.filter((file)=>item.uid==file.uid))
  //         urlArray.filter((item) => {
  //           return taskData.Upload_file.filter((file)=>{ console.log(file,item,'file,item'); return item.uid!==file.uid })

  //            })
  //           // urlArray.map((file) => {
  //           //   let allUrlRemoveFile = [];

  //           //     console.log(
  //           //       taskData.Upload_file,
  //           //       'taskData.Upload_file in resolve'
  //           //     );
  //           //   let url = taskData.Upload_file.filter((item, index) => {
  //           //       return item.url==
  //           //     });

  //           // })

  //         );
  //       }).then((newFileUrl) => {
  //         console.log(newFileUrl, 'newFileUrl');
  //         // if (newFileUrl.length >= 1) {
  //         //   console.log(true);
  //         // }
  //       });
  //     } else {
  //       fileDeleteApi(urlArray);
  //     }
  //   }

  //     //   new Promise (resolve=> { resolve(urlArray.filter((item,index)=>{  return !item.url}))}).then((newFileUrl)=>{console.log(newFileUrl,'newFileUrl');
  //     //       if(newFileUrl.length >= 1){console.log(true);}
  //     // })
  //     //   new Promise (resolve=> { resolve(urlArray.map((url)=>{
  //     //        let allUrlRemove = [];
  //     //       if (taskData.Upload_file.length>=1){
  //     //          console.log(taskData.Upload_file,'taskData.Upload_file in resolve');

  //     //         urlArray.filter((item,index)=>{  return !item.url})
  //     //       }else{

  //     //       }
  //     //   }))}).then((newFileUrl)=>{console.log(newFileUrl,'newFileUrl');
  //     //       if(newFileUrl.length >= 1){console.log(true);}
  //     // })
  //     // alert('allfiledelete');

  //   //     new Promise((resolve) => {
  //   //       if(taskData.Upload_file=''){
  //   //         alert('g')
  //   //       }
  //   //   resolve(taskData.Upload_file?.filter((item) => (!file.url&&file.uid === item.uid)));
  //   // }).then((i)=>{console.log(i,'iiiiii');})

  //   // let allUrlRemove =
  //   // if (urlArray[0]) {
  //   //   urlArray?.map((item) => {
  //   //     console.log(item.url, 'item.urlitem.url');
  //   //     const formData = new URLSearchParams();
  //   //     formData.append('image', item.url);

  //   //     Instance.post('auth/tasks/deleteImage', formData).then((result) => {
  //   //       console.log('delete result are', result.data);
  //   //       //   // changeflag.setflag(!changeflag.flag);
  //   //     });
  //   //   });
  //   // }
  // };
  // #endregion

  return (
    <>
      {/* <Button type="primary" onClick={() => setVisible(true)}>
        <MDBIcon fas icon="edit" />
      </Button> */}

      <Modal
        title="Edit Task"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        maskClosable={false}
        width={800}
        bodyStyle={{ padding: "20px 15px" }}
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            assignedDate:
              taskDatae?.Assigned_date !== "-"
                ? moment(
                  taskDatae?.Assigned_date
                    ? JSON.stringify(new Date(taskDatae?.Assigned_date)).slice(
                      1,
                      25
                    )
                    : ""
                )
                : "",
            dueDate:
              taskDatae.Due_date !== "-"
                ? moment(
                  taskDatae?.Due_date
                    ? JSON.stringify(new Date(taskDatae?.Due_date)).slice(
                      1,
                      25
                    )
                    : ""
                )
                : "",
          }}
        >
          <Row >
            <Col span={24} style={{ paddingRight: "10px" }}>
              <Form.Item
                label="Project Name"
                name="projectName"
                // value= {taskData.Project_name}
                initialValue={taskDatae.Project_name}
                rules={[
                  {
                    required: true,
                    message: "Please Select Short_summary",
                    whitespace: true,
                  },
                ]}
              >
                <Input disabled="true" />
              </Form.Item>
            </Col>
          </Row>

          <Row >
            <Col span={24} style={{ paddingRight: "10px" }}>
              <Form.Item
                label="Issue Type"
                name="IssueType"
                initialValue={taskDatae.Issue_type}
              >
                <Select >
                  <Select.Option value="Bug">Bug</Select.Option>
                  <Select.Option value="Task">Task</Select.Option>
                  <Select.Option value="Story">Story</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row >
            <Col span={24} style={{ paddingRight: "12px" }}>
              <Form.Item
                label="Short Summary"
                name="shortSummary"
                initialValue={taskDatae.Short_summary}
                rules={[
                  {
                    required: true,
                    message: "Please Select ",
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: "10px" }}>
              <div style={{ marginBottom: "10px" }}>
                Description<span style={{ color: "red" }}>*</span>
              </div>
              {console.log(taskDatae.Description, "drgdfgdf", taskDatae)}
              <div style={{ marginBottom: "25px" }}>
                <CKEditor

                  className="cknew-editor"
                  editor={ClassicEditor}
                  // activeClass="p10"
                  data={taskDatae ? taskDatae?.Description : "-------"}
                  content={content()}
                  config={config}
                  events={{
                    change: onDescriptionChange,
                  }}

                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTextEditer(data)
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col span={10} style={{display:'none' }} > */}
            {/* {console.log(taskDatae?.Upload_file,'taskDatae?.Upload_file')}
    {console.log(taskDatae?.Upload_file!='','taskDatae?.Upload_file')} */}


            <Col span={10}>
              <Form.Item
                label="Priority"
                name="priority"
                initialValue={taskDatae.Priority}
                rules={[
                  {
                    required: true,
                    message: "Please Select Priority!",
                    whitespace: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="HIGH">HIGH</Select.Option>
                  <Select.Option value="MEDIUM">MEDIUM</Select.Option>
                  <Select.Option value="LOW">LOW</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Assignees"
                name="assignees"
                initialValue={taskDatae.Assignees}
                rules={[
                  {
                    required: true,
                    message: "Please Select Assignees!",
                    whitespace: true,
                  },
                ]}
              >
                {/* <Select
                  onChange={(e) => setSelectedMember(e)}
                  options={assigneesList}
                /> */}

                <Select
                  onChange={(e) => setSelectedMember(e)}
                  showSearch="true"
                  optionFilterProp="children"
                >
                  {assigneesList?.map((item, index) => {
                    return (
                      <Select.Option value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div
            className="image"
            style={{ marginLeft: "70px", marginRight: "40px" }}
          >
            {olddocument !== undefined &&
              olddocument.map((element, index) => (
                <ImageDemo element={element} index={index} />
              ))}
          </div>

          <Row>
            <Col span={10}>
              <Form.Item
                label="Reporter"
                name="reporter"
                initialValue={taskDatae.Reporter}
                rules={[
                  {
                    required: true,
                    message: "Please Select Reporter!",
                    whitespace: true,
                  },
                ]}
              >
                {/* <Select>
                  {reporterList?.map((item, index) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select> */}

                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                style={{ marginTop: "-5px" }}
                label="Assigned Date"
                name="assignedDate"

              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select Assigned Date!",
              //   },
              // ]}
              // initialValue={taskData.Assigned_date}
              >
                <DatePicker
                  format={"DD/MM/YYYY"}
                  onChange={(e) => {
                    setAssignDate(e);
                  }}

                  defaultValue={moment(taskDatae.Assigned_date)}
                // disabled ="false"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* {taskDatae.Issue_type !== 'Bug' ? ( */}
            <>
              <Col span={10}>
                <Form.Item label="Due Date" name="dueDate">
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    // disabledDate={(d) => !d || d.isSameOrBefore()}
                    defaultValue={moment(taskDatae.Due_date)}
                  // disabledDate={(current) => {
                  //   return (
                  //     current && current < moment(assignDate, "YYYY-MM-DD")
                  //   );
                  // }}
                  />
                </Form.Item>
              </Col>
            </>
            {/* ) : ( */}
            <>
              <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Task Duration ( in hour, 30min = 0.5 )"
                  name="taskDuration"
                  initialValue={taskDatae?.Task_duration}
                  defaultValue={moment(
                    taskDatae?.Assigned_date)}

                // rules={[
                //   {
                //     required: true,
                //     message: 'Please Select Assigned Date!',
                //   },
                // ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Link issue"
                  name="linkissue"

                  initialValue={taskDatae.Linkissue_Taskkey == "undefined" ? '' : taskDatae.Linkissue_Taskkey}
                  rules={[
                    {
                      // required: true,
                      message: "Please Select Link issue",
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    // disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                    // onChange={(e) => setSelectedMember(e)}
                    showSearch="true"
                  // optionFilterProp="children"
                  >
                    {tasksList?.map((item, index) => {
                      return (
                        <Select.Option value={item.Task_key}>
                          {`${item.Task_key} - ${item.Short_summary}`}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

              </Col>
              <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Sprint"
                  name="sprint"
                  initialValue={taskDatae?.Sprint_id?.Name}
                  rules={[
                    {
                      // required: true,
                      message: 'Please Select Sprint',
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    showSearch='true'
                  >
                    {sprintdata?.map((item, index) => {
                      return (
                        <Select.Option value={JSON.stringify({ _id: item?._id, Name: item?.Name })}>
                          {item?.Name}
                        </Select.Option>
                      );
                    })}
                  </Select>

                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  style={{ marginTop: "5px" }}
                  label="Upload Document"
                  name="profile"
                // initialValue={taskData.Upload_file}
                // style={{alignItems:"center"}}
                // getValueFromEvent={normFile}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please Enter Project Name!',
                //   },
                // ]}
                >
                  <Upload
                    {...props}
                    className="upload-list-inline"
                  //  disabled='true'
                  >
                    <Button
                      className="uploadFile pms-same-btn"
                      style={{
                        color: "blue",
                        pointerEvents: "none !important",
                      }}
                      icon={<UploadOutlined />}
                    >
                      {`Upload`}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              {/* <Col span={10}>
                <Form.Item
                  // style={{ marginTop: '-5px' }}
                  label="Task Duration ( in hour, 30min = 0.5 )"
                  name="taskDuration"
                  initialValue={taskDatae?.Task_duration}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please Select Assigned Date!',
                  //   },
                  // ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col> */}
            </>
            {/* )} */}
          </Row>
          {
            //  taskData.Issue_type == "Bug"? <></>:
            // <Row>
            //   <Col span={10}>
            //     <Form.Item
            //       label="Status"
            //       name="status"
            //       initialValue={taskData.Status}
            //       rules={[
            //         {
            //           required: true,
            //           message: 'Please Select Status!',
            //         },
            //       ]}
            //     >
            //       <Select>
            //         <Select.Option value="Pending">Pending</Select.Option>
            //         <Select.Option value="InProgress">In-Progress</Select.Option>
            //         <Select.Option value="Done">Done</Select.Option>
            //       </Select>
            //     </Form.Item>
            //   </Col>
            // </Row>
          }

          {/* {taskDatae.Issue_type == "Bug" ? (
            <></>
          ) : (
          )} */}

          <Form.Item>
            <div style={{ textAlign: "right" }}>
              <Button
                className="pms-same-btn"
                type="primary"
                htmlType="submit"
                // onClick={chackDescription}
                style={{ marginRight: "10px" }}
              >
                Update
              </Button>
              <Button
                className="pms-same-btn-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;
