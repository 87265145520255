import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AxiosInstance from "../../../service/AxiosInstance";

import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";

import moment from "moment";
import DateDurationDatePicker from "../../DateDurationDatePicker";
import { DatePicker } from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import PmsRangePicker from "../../Me/LeaveModule/RangePicker";
const MilestoneReport = () => {
  const [milestoneList, setMilestoneList] = useState([]);
  const [datatable, setDatatable] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [result, setResult] = useState([]);

  const getMilestoneList = () => {
    AxiosInstance.get(`milestone/report`).then((res) => {
      setResult(res.data.data);
    });
  };

  useEffect(() => {
    getMilestoneList();
  }, []);

  useEffect(() => {



    let filteredResult = result;
    if (dateRange.length > 1) {
      filteredResult = result.filter((item, index) => {


        return moment(item?.DueDate) > dateRange[0] && moment(item?.DueDate) < dateRange[1];
      });
    }
    let list1 = filteredResult.map((item, index) => {
      return {
        icon: index + 1,
        Name: item?.Name,
        Project_name: item?.Project_id?.Project_name,
        Description: item?.Description,
        StartDate: item?.StartDate
          ? moment(item?.StartDate).format("DD/MM/YYYY")
          : "",
        DueDate: item?.DueDate
          ? moment(item?.DueDate).format("DD/MM/YYYY")
          : "",
        Status: item?.Status,
      };
    });

    setMilestoneList(list1);
  }, [result, dateRange]);
  useEffect(() => {
    console.log(dateRange);
    console.log(moment(dateRange[0]).format("DD MMM YYYY"));
  }, [dateRange]);

  const onChange = (value, dateString) => {
    setDateRange(value);
    console.log(value, dateString);
  };

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Project Name",
          field: "Project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Name",
          field: "Name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Description",
          field: "Description",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Description",
          },
        },

        {
          label: "Start Date",
          field: "StartDate",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Due Date",
          field: "DueDate",
          width: 270,
          sort: "disabled",
        },

        {
          label: "Status",
          field: "Status",
          width: 200,
          sort: "disabled",
        },
      ],
      rows: milestoneList,
    });
  }, [milestoneList]);

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol
              // style={{ marginLeft: '7px' }}
              size="md"
              className="col-example  titel-set"
            >
              {" "}
              <>
                <div className="leave-set-btn2">
                  <h3 className="tablHeader">Milestone Report</h3>
                </div>
              </>
            </MDBCol>
          </div>
          <div>
            <MDBCol size="md-3">
              <label
                className="start-list"
              >
                Due Date
              </label>
              <PmsRangePicker
                className="search-rangepicker"
                // style={{paddingLeft:'10px',width:'100%'}}
                format="DD-MM-YYYY"
                onChange={onChange}
              />
            </MDBCol>
          </div>

          <hr />

          <div className="scrool-set">
            <MDBDataTableV5
              hover
              entriesOptions={[10, 20, 25]}
              entries={10}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default MilestoneReport;
