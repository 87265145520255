import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";

import axiosInstance from "../../service/AxiosInstance";
import moment from "moment";
import { Link } from "react-router-dom";

export default function ProjectStatusReport() {
  const [flag, Setflag] = useState(false);
  const [employees, Setemployees] = useState([]);
  const [datatable, setDatatable] = React.useState({});
  const UserData = localStorage.getItem("userdata")
    ? localStorage.getItem("userdata")
    : undefined;
  let Position_Id = UserData ? JSON.parse(UserData)[0].Position_id : undefined;

  useEffect(() => {
    axiosInstance.get("auth/report/project-status").then(async (result) => {
      if (result.data.data) {
        let employeesdeta = await result.data.data.map((item) => {
          return {
            name: `${item?.Project_id?.Project_name}`,
            status: `${item?.Project_id?.Status}`,
            // name: (
            //   <Link style={{fontWeight:'500'}}
            //     to={{ pathname: `/employeeDetail/${item._id}` }}
            //   >{`${item.First_name} ${item.Middle_name} ${item.Last_name}`}</Link>
            // ),

            comment: item.Comment,

            Create:
              moment(item.Create_at).format("DD MMM, HH:mm") +
              " by " +
              `${item.First_name} ${item.Last_name}`,

            action: (
              <Link
                style={{ fontWeight: "500" }}
                to={{ pathname: `/project/view/${item.Task_id}` }}
              >{`View`}</Link>
            ),
          };
        });
        Setemployees(employeesdeta);
      }
    });
  }, [flag]);

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "Project Name",
          field: "name",
          // sort: 'disabled',
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Project Status",
          field: "status",
          // sort: 'disabled',
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Comment",
          field: "comment",
          sort: "disabled",
          width: 270,
        },
        {
          label: "Created At",
          field: "Create",

          width: 270,
        },
        {
          label: "Action",
          field: "action",

          width: 270,
        },

      ],
      rows: employees,
    });
  }, [flag, employees]);

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div>
            <div className="create-project">
              <MDBCol size="md" className="col-example">
                <h3 className="tablHeader">Project Status Update</h3>
              </MDBCol>
            </div>
          </div>
          <div className="tasklisttbl pro-status-tbl">
            <MDBDataTableV5
              hover
              entriesOptions={[20, 25, 30]}
              entries={20}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>
        </MDBCard>
      </div>

    </>
  );
}

//
