import React, { useEffect, useState } from "react";

import "antd/dist/antd.css";
import { useHistory, Link } from "react-router-dom";
import SelectEmpployee from "../Common/SelectEmpployee";
import moment from "moment";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";

const Attendancebar = (props) => {
  return (
    <>
      <MDBNavbarNav className="page-menu-nav">
        <MDBNavbarItem> 
          <Link to="/report/attendance-summary" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
             Attendance Summary
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <Link to="/employeepresentlist" active>
            {" "}
            <MDBNavbarLink active aria-current="page" href="#">
            Employee Present List
            </MDBNavbarLink>
          </Link>
        </MDBNavbarItem>
        {" "}
      </MDBNavbarNav>
    </>
  );
};

export default Attendancebar;
