import { Select, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import AxiosInstance from '../../../service/AxiosInstance';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { Status } from '../../Options';
import { taskPriorityBadge, taskStatusBadge } from '../../Common/Coomon';
const TaskTable = ({ employeeDetails }) => {

  const history = useHistory()
  const [myTeamTask, setmyTeamTask] = useState([])
  const [myTask, setMyTask] = useState([])
  const [statusSort, setStatusSort] = useState('ALL');
  const columns = [
    {
      title: '#',
      dataIndex: 'taskKey',
    },
    {
      title: 'Summary',
      dataIndex: 'shortSummary',
    },
    {
      title: 'P',
      dataIndex: 'priority',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'AssignedDate',
      dataIndex: 'assignedDate',
    },
    {
      title: 'Time',
      dataIndex: 'assignedTime',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
    },
  ];

  useEffect(() => {
    if (employeeDetails?.id) {
      getByUserIdTasks()
    } else {
      getDefaultUserTasks()
    }
  }, [employeeDetails])
  console.log(employeeDetails, 'employeeDetailsemployeeDetailsemployeeDetails');
  useEffect(() => {
    getTaskList()
  }, [statusSort])

  const getDefaultUserTasks = () => {
    AxiosInstance.get('auth/employeetasks/list/Default').then(async (res) => {
      setmyTeamTask(res.data.data)
    })
  }

  const getByUserIdTasks = () => {
    const urlId = new URLSearchParams();
    urlId.append('id', employeeDetails?.id)
    AxiosInstance.post('employeetasks/list', urlId).then(async (res) => {
      const reverseData = [...res.data.data].reverse();
      setmyTeamTask(reverseData)
    })
  }

  const getTaskList = () => {
    let data = [];
    myTeamTask?.map((row) => {
      let detail = {
        taskKey: <Link style={{ fontWeight: '500' }} to={`/task/${row?._id}`}>{row?.Task_key}</Link>,
        shortSummary: `${row?.Short_summary.substring(0, 50)}`,
        priority: taskPriorityBadge(row.Priority),
        status: taskStatusBadge(row.Status),
        assignedDate: `${moment(row.Assigned_date2).format("DD/MM/YYYY")}`,
        assignedTime: `${row.Task_duration?.trim() !== "undefined" && row.Task_duration ? row.Task_duration > 1 ? `${row?.Task_duration} Hr` : `${row?.Task_duration} Hr` : '-'}`,
        dueDate: `${row?.Due_date && row?.Due_date !== '-' && row?.Due_date !== 'Invalid date'
          ? moment(row?.Due_date).format("MMM DD")
          : '-'}`
      }
      if (statusSort != "ALL") {
        if (statusSort === row.Status) {
          data.push(detail);
        }
      } else {
        data.push(detail);
      }

    })
    setMyTask(data)
  }
  useEffect(() => {
    getTaskList();
  }, [myTeamTask])
  return (
    <> <div className='my-task-dropdown'>
      <Select
        // style={{ width: '100%' }}
        className="select-dropdown box-width"
        value={statusSort}
        options={Status}
        onChange={(e) => setStatusSort(e)}
      />
    </div>
      <Table className='team-table' columns={columns} dataSource={myTask} />
    </>
  )
}

export default TaskTable;