import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard, MDBRow } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { MDBNavbarItem, MDBNavbarLink, MDBNavbarNav } from "mdb-react-ui-kit";
import Attendancebar from "./Attendancebar";

import axiosInstance from "../../service/AxiosInstance";
import {
  Button,
  Drawer,
  Select,
  Col,
  Row,
  Space,
  Form,
} from "antd";
import moment from "moment";
import { AttendanceOption } from "../Options";
import FaceLogs from "./FaceLogs";
import AttendanceDetails from "./AttendanceDetails";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { ExportOutlined } from "@ant-design/icons";
export default function AttendanceSummary() {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [employees, Setemployees] = useState([]);
  const [loading, setLoading] = useState("");
  const [date, setDate] = useState(moment());
  const [datatable, setDatatable] = React.useState({});
  const [attendanceOption, setAttendanceOption] = useState("ALL");
  const [visible, setVisible] = useState(false);

  const [year, setYear] = useState(2022);
  const [month, setMonth] = useState(12);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState(12);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setMonth(values?.month);
    setYear(values?.year);
    getDetails(values?.month, values?.year);
  };

  useEffect(() => {
    let showResult = employees.filter((e) => {
      if (attendanceOption == "ABSENT") {
        return e.faceinOut == "" || e.faceinOut == undefined;
      }
      if (attendanceOption == "PRESENT") {
        return e.faceinOut !== undefined && e.faceinOut !== "";
      }
      return true;
    });



    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },

        {
          label: "Shift Name",
          field: "shiftname",
        },
        {
          label: "Location",
          field: "Location",
        },
        {
          label: "Working Days",
          field: "workingDays",
        },
        {
          label: "Present",
          field: "present",
        },
        {
          label: "Absent",
          field: "absent",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: showResult,
    });
  }, [employees, attendanceOption]);
  const showDetails = (data) => {
    setSelectedEmployeeID(data);
    showDrawer();
  };

  const getDetails = (m, y) => {
    setLoading("Generating report....");

    const urlencoded = new URLSearchParams();
    urlencoded.append("month", m);
    urlencoded.append("year", y);

    axiosInstance
      .post(
        "auth/attendance/summarybymonth?date=" + date.format("YYYY-MM-DD"),
        urlencoded
      )
      .then(async (result) => {
        setLoading("");
        if (result.data.data) {
          let employeesdeta = await result.data.data.map((item) => {
            return {
              name: `${item.employee.First_name} ${item.employee.Middle_name} ${item.employee.Last_name}  (${item.employee.Employee_code})`,

              workingdays: "",
              present: item.attendance.present,
              absent: item.attendance.absent,
              workingDays: item.attendance.workingDays,
              shiftname: item.employee.Shift_type,
              Location:item?.employee?.Location,
              action: (
                <>
                  <Button
                    className="pms-same-btn"
                    style={{ fontWeight: "500" }}
                    onClick={() => showDetails(item.employee._id)}
                  >
                    View
                  </Button>
                </>
              ),
            };
          });
          Setemployees(employeesdeta);
        }
      });
  };

  const handleChanges = (e) => {
    setDate(e);
    console.log(e.format("YYYY-MM-DD"));
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const checkAction = () => {
    exportAll(employees, "Attendance-Summary");
  };
  const exportAll = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    delete ws["H1"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <div className="demo">
        <MDBNavbarNav>
          <div>
            <Attendancebar />
          </div>
          <div className="tasklisttbl page-content">
            <div className="attendance-year create-project">
              <p>For The Period</p>
              <Space direction="horizontal">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row>
                    <Col style={{ paddingRight: "12px" }}>
                      <Form.Item
                        // label="Select month"
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Month",
                          },
                        ]}
                      >
                        <Select showSearch="true" placeholder="Select month">
                          <Select.Option value="01">January</Select.Option>
                          <Select.Option value="02">February</Select.Option>
                          <Select.Option value="03">March</Select.Option>
                          <Select.Option value="04">April</Select.Option>
                          <Select.Option value="05">May</Select.Option>
                          <Select.Option value="06">June</Select.Option>
                          <Select.Option value="07">July</Select.Option>
                          <Select.Option value="08">August</Select.Option>
                          <Select.Option value="09">September</Select.Option>
                          <Select.Option value="10">October</Select.Option>
                          <Select.Option value="11">November</Select.Option>
                          <Select.Option value="12">December</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingRight: "12px" }}>
                      <Form.Item
                        // label="Select Year"
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Year",
                          },
                        ]}
                      >
                        <Select showSearch="true" placeholder="Select year">
                          <Select.Option value="2022">2022</Select.Option>
                          <Select.Option value="2023">2023</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item>
                        <Button className="pms-same-btn" htmlType="submit">
                          Refresh
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Space>
            </div>
            <span className="btn-right right-emp-btn attendance-btn">
              <button
                type="button"
                className="ant-btn export-btn pms-same-btn"
                onClick={() => checkAction()}
              >
                <ExportOutlined />
              </button>
            </span>
            <MDBDataTableV5
              hover
              entriesOptions={[20, 25, 30]}
              entries={20}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>
        </MDBNavbarNav>
        <Drawer
          title="Attandance logs"
          placement="bottom"
          height={600}
          onClose={onClose}
          visible={visible}
        >
          {visible && (
            <AttendanceDetails
              year={year}
              month={month}
              employee_id={selectedEmployeeID}
            />
          )}
        </Drawer>
      </div>
    </>
  );
}

//
