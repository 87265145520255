import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import AxiosInstance from "../../../service/AxiosInstance";
import moment from "moment";

const DueSprint = () => {
  const [datatable, setDatatable] = useState({});
  const [sprintData, setSprintData] = useState([]);

  const GetAllMileStone = () => {
    AxiosInstance.get("/sprint/seventhDaysDueSprint").then((res) => {
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          name: item?.Name,
          project_name: item?.Project_id?.Project_name,
          dueDate: item?.DueDate
            ? moment(item?.DueDate).format("DD/MM/YYYY")
            : "",
          status: item?.Status,
        };
      });

      setSprintData(list1);
    });
  };

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Name",
          field: "name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Project Name",
          field: "project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Status",
          field: "status",
          width: 200,
          sort: "disabled",
        },
        {
          label: "Due Date",
          field: "dueDate",
          width: 270,
          sort: "disabled",
        },
      ],
      rows: sprintData,
    });
  }, [sprintData]);

  useEffect(() => {
    GetAllMileStone();
  }, []);

  return (
    <>
      <div className="team-text">
        <p>Due Sprint</p>
      </div>
      <div
        className="team-table scrool-set milestone-tbl tasklisttbl due-table"
        style={{ marginTop: "10px" }}
      >
        <MDBDataTableV5
          hover
          entriesOptions={[10, 20, 25]}
          entries={10}
          pagesAmount={4}
          data={datatable}
          searchTop
          searchBottom={false}
        />
      </div>
    </>
  );
};

export default DueSprint;
