import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AxiosInstance from "../../service/AxiosInstance";

import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import moment from "moment";

const TaskTimerReport = () => {
  const [takstimerList, settakstimerList] = useState([]);
  const [datatable, setDatatable] = useState({});

  const getTaskTimerList = () => {
    AxiosInstance.get(`auth/tasks/tasktimer/report`).then((res) => {
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          Emplopyee_name:
            item?.Employee_id?.First_name + "" + item?.Employee_id?.Last_name,
          Project_name: item?.Project_id?.Project_name,
          Short_summary: item?.Task_id?.Short_summary,
          Check_In: item?.Check_In
            ? moment(item?.Check_In).format("DD/MM/YYYY hh:mm:ss A")
            : "",
          Check_Out: item?.Check_Out
            ? moment(item?.Check_Out).format("DD/MM/YYYY hh:mm:ss A")
            : "",
          Status: item?.Status,
        };
      });

      settakstimerList(list1);
    });
  };

  useEffect(() => {
    getTaskTimerList();
  }, []);

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Employee Name",
          field: "Emplopyee_name",
          width: 150,
          sort: "disabled",
        },

        {
          label: "Project Name",
          field: "Project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Task Short Summry",
          field: "Short_summary",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Description",
          },
        },

        {
          label: "Check In",
          field: "Check_In",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Check Out",
          field: "Check_Out",
          width: 270,
          sort: "disabled",
        },

        {
          label: "Status",
          field: "Status",
          width: 200,
          sort: "disabled",
        },
      ],
      rows: takstimerList,
    });
  }, [takstimerList]);

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol
              // style={{ marginLeft: '7px' }}
              size="md"
              className="col-example  titel-set"
            >
              {" "}
              <>
                <div className="leave-set-btn2">
                  <h3 className="tablHeader">Task Timer Report</h3>
                </div>
              </>
            </MDBCol>
          </div>

          <div className="scrool-set">
            <MDBDataTableV5
              hover
              entriesOptions={[10, 20, 25]}
              entries={10}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default TaskTimerReport;
