import React from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";
import MeBar from "./MeBar";
import Leaves from "../../components/Me/LeaveModule/List";

// main component
const Me = () => {
  return (
    <>
      <div className="event-main">
        <MeBar />
        <Row className="mx-0 mydashboard-main ">
          <Col xs={24}>
            <Leaves />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Me;
