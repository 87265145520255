import React from 'react';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

const DateDurationDatePicker = ({setAssignedDateRange, SetDueDateRange}) => {
    
    const onChange = (value, dateString)=>{
      
      // console.log('dateString: ',dateString);
      // let formettedDate =[ dateString[0].replace(/-/g,'/'),dateString[1].replace(/-/g,'/')];
      //   // console.log('formettedDate: ', formettedDate);
      // let DateToMiliSeconds = [value?Date.parse(value[0]._d)-86400000:'',value?Date.parse(value[1]._d):'']

      let DateToMiliSeconds = [value?Math.floor((Date.parse(value[0]._d)/86400000)):'',value?Math.floor((Date.parse(value[1]._d)/86400000 )):'']
      // console.log(value,'valuevaluevaluevaluevaluevaluevaluevalue');
      
      if(setAssignedDateRange){
      setAssignedDateRange(DateToMiliSeconds)
    }
      if(SetDueDateRange){
      SetDueDateRange(DateToMiliSeconds)
    }
    // console.log('Selected Time  value[0].Moment: ', value);
    }
  
  return (
    <>
      <RangePicker
              className='search-rangepicker'
              // style={{paddingLeft:'10px',width:'100%'}}
              format="DD-MM-YYYY"
              onChange={onChange} 
      />
    </>
  );
};
export default DateDurationDatePicker;