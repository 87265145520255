import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
} from 'antd';
import PmsDatePicker from '../Me/LeaveModule/PmsDatePicker';
import { UploadOutlined } from '@ant-design/icons';
import Instance from '../../service/AxiosInstance';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { MDBIcon } from 'mdb-react-ui-kit';
let date = new Date();
// main component

//getting loggedin user data

let userData = JSON.parse(localStorage.getItem("userdata"));
let PID = localStorage.getItem("PID");

const repoter_name = userData?.[0].First_name + ' ' + userData?.[0].Last_name

//getting project id


const Add = ({ setFlage, setFlage1, btnStyl, projectDetail, list, inSidebar, setTaskAddIsShow, taskList }) => {

  const params = useParams();
  //getting perticular project tasks list
  const [tasksList, setTasksList] = useState([])


  // useEffect(() => {
  const GetprojectTaskList = () => {

    Instance.get(`auth/projecttasks/list/${projectDetail._id}`)
      .then((res) => {
        console.log("data is", res.data.data);
        setTasksList(res.data.data)

      })
  }
  // }, [])
  useEffect(() => {
    GetprojectTaskList();
  }, []);

  const [form] = Form.useForm();
  const [projectList, setProjectList] = useState();
  const [count, setCount] = useState(1);
  const [visible, setVisible] = useState(true);
  const [textEditer, setTextEditer] = useState('');
  const [sprintdata, setSprintdata] = useState([]);
  const [ProjectIDeOnChange, setProjectIDeOnChange] = useState('');
  const [reporterList, setReporterList] = useState([]);
  const [assigneesList, setAssigneesList] = useState([]);
  // console.log(assigneesList,'assigneesList::::::::::');
  const [selectedMember, setSelectedMember] = useState('');
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [isueType, setIsueType] = useState();
  const [loading, setLoading] = useState(false)
  // const [url, setUrl] = useState('');
  const [urlArray, setUrlArray] = useState([]);
  // let urlArray = [];
  let filterUrl;
  // const [urlArray, setUrlArray] = useState([])
  let config = {
    toolbarGroups: [
      // { name: "document", groups: ["mode", "document", "doctools"] },

      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'links', groups: ['link'] },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
      },
      { name: 'styles', groups: ['Styles', 'Format', 'Font', 'FontSize'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] },
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker', 'editing'],
      },
    ],
    removeButtons:
      'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo',
    fontSize_sizes: '16/16px;24/24px;48/48px;',
    font_names:
      'Arial/Arial, Helvetica, sans-serif;' +
      'Times New Roman/Times New Roman, Times, serif;' +
      'Verdana',
    allowedContent: true,
    scayt_autoStartup: true,
  };

  // const [descriptionAlert, setDescriptionAlert] = useState('');

  // function chackDescription() {
  //   if (textEditer === '') setDescriptionAlert('Please Enter Description');
  //   if (textEditer !== '') setDescriptionAlert('');
  // }

  const getSprintData = () => {
    const urlencoded = new URLSearchParams()
    urlencoded.append('Project_id', params?.id)
    Instance.post('sprint/getAllsprint', urlencoded).then((res) => {
      setSprintdata(res.data.data);
    });
  }

  useEffect(() => {
    getSprintData();
  }, [params?.id])

  useEffect(() => {
    // if(projectDetail){
    Instance.get('auth/projects').then((res) => {
      // console.log(res, 'kk');
      setProjectList(res.data.data);
      // console.log(projectList, 'projectList');
    });
    // }
  }, []);

  useEffect(() => {
    // console.log(projectDetail,'projectDetail::::::::::::::::::::');
    if (projectDetail?.Project_member) {
      // let projectMember = projectDetail?.Project_member
      //   ? projectDetail?.Project_member.split(',')
      //   : '';
      //Employee
      Instance.get(`auth/projectemployeelist/${projectDetail._id}`).then(async (res) => {
        if (res.data.status == 1) {
          let EmID =
            res.data.data.map((member) => {
              // let EmID1 = res.data.data.filter(
              //   (item) => item.First_name + ' ' + item.Last_name == member
              // );
              return {
                label:
                  member?.First_name +
                  ' ' +
                  member?.Last_name +
                  ' (' +
                  member?.Role +
                  ')',
                value: member?.First_name + ' ' + member?.Last_name,
              };
            });
          // console.log(EmID,'EmID1::::::::::::');
          setAssigneesList(EmID);
          setSelectedMember(EmID);
        }
      });
      //let projectMan = projectDetail?.Project_manage?.split(',');
      //let projectMan = projectDetail?.Project_member?.split(',');
      //setReporterList(userData[0].First_name);
    }
  }, [projectDetail]);

  // project data List
  useEffect(() => {
    // console.log(projectList,"dpdsssaaaaffff")
    let projectData = projectList?.filter(
      (item) => item._id == ProjectIDeOnChange
    );
    if (ProjectIDeOnChange) {
      // let projectMember = projectData[0]?.Project_member.split(',');
      //Employee
      Instance.get(`auth/projectemployeelist/${ProjectIDeOnChange}`).then(async (res) => {
        if (res.data.status == 1) {
          let EmID =
            res.data.data.map((member) => {
              // let EmID1 = res.data.data.filter(
              //   (item) => item.First_name + ' ' + item.Last_name == member
              // );
              return {
                label:
                  member?.First_name +
                  ' ' +
                  member?.Last_name +
                  ' (' +
                  member?.Role +
                  ')',
                value: member?.First_name + ' ' + member?.Last_name,
              };
            });
          // console.log(EmID,'EmID1::::::::::::');
          setAssigneesList(EmID);
          setSelectedMember(EmID);
        }
      });
      // setAssigneesList(projectMember);
      let projectMan = projectData[0]?.Project_manager?.split(',');
      setReporterList(projectMan);
    }
  }, [ProjectIDeOnChange]);

  // useEffect(() => {
  //   console.log(projectList,"dpdsssaaaaffff")
  //   let projectData = projectList?.filter(
  //     (item) => item._id == ProjectIDeOnChange
  //   );
  //   if (ProjectIDeOnChange) {
  //     let projectMember = projectData[0]?.Project_member.split(',');

  //     //Employee
  //     Instance.get(`auth/manageremployees/61af44fb8fbced36f9e324a1`).then(async (res) => {
  //       if (res.data.status == 1) {
  //         let EmID =
  //           projectMember &&
  //           projectMember.map((member) => {
  //             let EmID1 = res.data.data.filter(
  //               (item) => item.First_name + ' ' + item.Last_name == member
  //             );
  //             return {
  //               label:
  //                 EmID1[0]?.First_name +
  //                 ' ' +
  //                 EmID1[0]?.Last_name +
  //                 ' (' +
  //                 EmID1[0]?.Role +
  //                 ')',
  //               value: EmID1[0]?.First_name + ' ' + EmID1[0]?.Last_name,
  //               // value:  EmID1[0]?._id,
  //             };
  //           });
  //         setAssigneesList(EmID);
  //         setSelectedMember(EmID);
  //       }
  //     });
  //     // setAssigneesList(projectMember);
  //     let projectMan = projectData[0]?.Project_manager.split(',');
  //     setReporterList(projectMan);
  //   }
  // }, [ProjectIDeOnChange]);
  // employee list
  useEffect(() => {
    if (projectDetail) {

      Instance.get(`auth/projectemployeelist/${projectDetail._id}`).then(async (res) => {
        if (res.data.status == 1) {
          let EmID = await res.data.data.filter(
            (item) => item.First_name + ' ' + item.Last_name == selectedMember
          );
          setSelectedMemberId(EmID[0]?._id);
        }
      });
    } else if (ProjectIDeOnChange) {
      Instance.get(`auth/projectemployeelist/${ProjectIDeOnChange}`).then(async (res) => {
        if (res.data.status == 1) {
          let EmID = await res.data.data.filter(
            (item) => item.First_name + ' ' + item.Last_name == selectedMember
          );
          setSelectedMemberId(EmID[0]?._id);
        }
      });
    }
  }, [selectedMember]);

  // upload file props
  const props = {
    className: 'upload-list-inline',
    listType: 'picture',
    maxCount: '50',
    multiple: 'multiple',
    openFileDialogOnClick: 'false',
    action: 'demo_image',

  };

  //#region all uploaded file delete fun
  const allFileDelete = () => {
    // if (urlArray[0]) {
    //   urlArray?.map((item) => {
    //     console.log(item.url, 'item.urlitem.url');
    //     const formData = new URLSearchParams();
    //     formData.append('image', item.url);
    //     Instance.post('auth/tasks/deleteImage', formData).then((result) => {
    //       console.log('delete result are', result.data);
    //       //   // changeflag.setflag(!changeflag.flag);
    //     });
    //   });
    // }
  };
  //#endregion
  useEffect(() => {
    // if (setFlage1) {
    //   setFlage1((pre) => pre + 1);
    // }
    if (setFlage) {
      setFlage((pre) => pre + 1);
    }
  }, [visible]);


  //submit
  const onFinish = async (values) => {
    let Project_Id = projectList.filter(
      (item, index) => values.projectShortName === item._id
    );
    let taskKey = `${projectDetail
      ? projectDetail?.Project_short_name
      : Project_Id[0]?.Project_short_name
      }`;

    // console.log(taskKey, 'dddddddddddddddddd');
    let sptId;
    let sptName;
    if (values.sprint == projectDetail.Sprint_id) {
      sptName = values.sprint
      sptId = projectDetail.Sprint_id
    } else {
      sptName = JSON.parse(values.sprint)?.Name
      sptId = JSON.parse(values.sprint)?._id
    }
    if (values) {
      const formData = new FormData();
      formData.append('Issue_type', values.IssueType);
      if (sptId) formData.append('Sprint_id', sptId ? sptId : '-');
      formData.append('Short_summary', values.shortSummary);
      formData.append('Description', textEditer);
      formData.append('Reporter', values.reporter ? values.reporter : '-');
      // formData.append(
      //   'Upload_file',
      //   urlArray[0] ? JSON.stringify(urlArray) : ''
      // );

      // {console.log("Valuess -------------------------", values.linkissue);}
      formData.append(
        'Assigned_date',
        values.assignedDate ? values.assignedDate : '-'
      );
      if (values.profile) {
        for (let i = 0; i < values.profile.fileList.length; i++) {
          formData.append("demo_image", values.profile.fileList[i].originFileObj);
        }
      }
      formData.append('Due_date', values.dueDate ? values.dueDate : '-');
      // formData.append('Assignees', selectedMemberId);
      formData.append('Assignees', values.assignees ? values.assignees : '');
      formData.append('Priority', values.priority);
      // formData.append('Upload_file',data.length>1?JSON.stringify(data):'')

      if (projectDetail) {
        formData.append('Project_name', projectDetail?.Project_name);
        formData.append('Project_id', projectDetail._id);
      } else {
        formData.append('Project_name', Project_Id[0]?.Project_name);
        formData.append('Project_id', values.projectName);
      }
      formData.append('Employee_id', selectedMemberId);
      formData.append('Task_key', taskKey);
      formData.append('Task_duration', values.taskDuration);

      formData.append('Linkissue_Taskkey', values.linkissue);
      setLoading(true)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      await Instance.post('uplodeauth/tasks/add', formData, config)
        .then((res) => {
          setLoading(false)
          if (res.data.status == 1) {
            toast.success("Task created Successfully");
            form.resetFields();
            setTextEditer('');
            setIsueType('');
            console.log(res, 'res');
            // setTextEditer('');
            // setFlage1((pre) => pre + 1);
            setCount((pre) => pre + 1);
            setTaskAddIsShow(false)
            // setflag(pre=>pre+1);
            taskList();
          }
          setUrlArray([]);
          setVisible(false);
        })
        .catch((err) => { console.log(err, 'err') });
    }
  };

  // console.log('textEditer:', textEditer);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    // setTextEditer('');
  };

  const handleCancel = (errorInfo) => {
    allFileDelete();

    console.log('Failed:', errorInfo);
    form.resetFields();
    setTextEditer('');
    setVisible(false);
    setIsueType('');
    setProjectIDeOnChange('')
    setUrlArray([])
    setTaskAddIsShow(false)
    // setFlage1((pre) => pre + 1);

    // setProjectIDeOnChange('')
    // urlArray = [];
  };

  const onDescriptionChange = (evt) => {
    // setTextEditer(evt.editor.getData());
    console.log(evt.editor.getData(), 'evt.editor.getData()');
  };
  // console.log(urlArray, 'urlArray out');

  return (
    <>

      {/* {btnStyl ? (<> */}
      {/* {alert('hello')} */}
      {/* <Button className="btn-add-link tablHeader" onClick={() => setVisible(true)}>
          <MDBIcon fas icon="plus" />
          <span style={{  paddingLeft: '15px', fontWeight: '600' }}>
            
          </span> */}
      {/* </Button> */}
      {/* </>) : null} */}
      {/* (
        <Button style={ { margin: "5px 9px 0px 10px"}} type="primary" onClick={() => setVisible(true)}>
          
        </Button>
      ) */}

      <Modal
        title="Create Task"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        maskClosable={false}
        width={800}
        height={640}
        bodyStyle={{ padding: '20px 15px' }}
        footer={null}
      >
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            {projectDetail?.Project_name ? (
              <Col span={24} style={{ paddingRight: '10px' }}>
                <Form.Item
                  label="Project Name"
                  name="projectName"
                  initialValue={projectDetail?.Project_name}
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Project Name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            ) : (
              <Col span={24} style={{ paddingRight: '10px' }}>
                <Form.Item
                  label="Project Name"
                  name="projectName"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Project Name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Select onChange={(e) => setProjectIDeOnChange(e)}
                    showSearch='true'
                    optionFilterProp="children"
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                  >
                    {projectList?.map((item, index) => {
                      return (
                        <Select.Option value={item._id}>
                          {item.Project_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row>
            <Col span={12} style={{ paddingRight: '10px' }}>
              <Form.Item
                label="Issue Type"
                name="IssueType"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Issue Type!',
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    setIsueType(e);
                  }}
                >
                  <Select.Option value="Bug">Bug</Select.Option>
                  <Select.Option value="Task">Task</Select.Option>
                  <Select.Option value="Story">Story</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <Form.Item
                label="Short Summary"
                name="shortSummary"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Short Summary',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ paddingRight: '10px' }}>
              <div style={{ marginBottom: '10px', marginLeft: '11px' }}>
                Description<span style={{ color: 'red' }}>*</span>
              </div>
              <div style={{ marginBottom: '25px' }}>
                <CKEditor
                  className="cknew-editor"
                  editor={ClassicEditor}
                  // activeClass="p10"
                  config={config}
                  content={textEditer}
                  events={{
                    change: onDescriptionChange,
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTextEditer(data)
                  }}

                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={10}>
              <Form.Item
                style={{ marginTop: '5px' }}
                label="Upload File"
                name="profile"
              // onPreview={onPreview}
              // style={{alignItems:"center"}}
              // getValueFromEvent={normFile}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please Enter Project Name!',
              //   },
              // ]}
              >
                <Upload {...props} className="upload-list-inline">
                  <Button
                    className="uploadFile pms-same-btn"
                    style={{
                      color: 'blue',
                      width: '340px',
                      pointerEvents: 'none !important',
                    }}
                    icon={<UploadOutlined />}
                  >
                    {`Upload`}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Priority"
                name="priority"
                initialValue={"MEDIUM"}
                rules={[
                  {
                    required: true,
                    message: 'Please Select Priority!',
                    whitespace: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="HIGH">HIGH</Select.Option>
                  <Select.Option value="MEDIUM">MEDIUM</Select.Option>
                  <Select.Option value="LOW">LOW</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Assignees"
                name="assignees"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Assignees!',
                    whitespace: true,
                  },
                ]}
              >
                {/* <Select onChange={(e) => setSelectedMember(e)}>
                  {assigneesList?.map((item, index) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select> */}
                {/* <Select
                  onChange={(e) => setSelectedMember(e)}
                  options={assigneesList}
                /> */}


                <Select
                  disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                  onChange={(e) => setSelectedMember(e)}
                  showSearch='true'
                  optionFilterProp="children"

                >
                  {assigneesList?.map((item, index) => {
                    return (
                      <Select.Option value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>

              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Reporter"
                name="reporter"
                initialValue={repoter_name}
                // initialValue={

                //   projectDetail?.Project_manager
                //     ? projectDetail?.Project_manager
                //     : reporterList[0]
                // }

                rules={[
                  {
                    required: true,
                    message: 'Please Select Reporter!',
                    whitespace: true,
                  },
                ]}

              >

                <Input disabled />
                {/* <Form.Item label="Reporter" name="reporter" initialValue={reporterList[0]}> */}
                {/* <Select
                  disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                >
                  {reporterList?.map((item, index) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select> */}
              </Form.Item>
            </Col>
          </Row>
          {/* {console.log(isueType, 'isueTypeisueTypeisueType ')} */}
          <Row>
            <Col span={10}>
              <Form.Item
                style={{ marginTop: '-5px' }}
                label="Assigned Date"
                name="assignedDate"
              // initialValue={moment(`${(date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear())}`, 'DD-MM-YYYY')}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please Select Assigned Date!',
              //   },
              // ]}
              >

                <PmsDatePicker
                  format={'DD/MM/YYYY'}
                  // defaultValue={moment(`${(date.getDate()+'-'+date.getMonth()+'-'+date.getFullYear())}`, 'YYYY-MM-DD')}
                  disabledDate={(d) => !d || d.isBefore(Date.now() - 86400000)}
                />
              </Form.Item>
            </Col>
            <>
              <Col span={10}>
                <Form.Item label="Due Date" name="dueDate">
                  <PmsDatePicker
                    format={'DD/MM/YYYY'}
                    disabledDate={(d) => d < moment(Date.now() - 86400000)}
                  />
                </Form.Item>
              </Col>
            </>
            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Task Duration ( in hour, 30min = 0.5 )"
                name="taskDuration"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please Select Assigned Date!',
              //   },
              // ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>


            {/* link issue */}


            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Link issue"
                name="linkissue"
                rules={[
                  {
                    // required: true,
                    message: 'Please Select Link issue',
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  // disabled={!inSidebar && (ProjectIDeOnChange ? false : true)}
                  // onChange={(e) => setSelectedMember(e)}
                  showSearch='true'
                // optionFilterProp="children"

                >
                  {tasksList?.map((item, index) => {
                    return (
                      <Select.Option value={item.Task_key}>
                        {`${item.Task_key} - ${item.Short_summary}`}
                      </Select.Option>
                    );
                  })}
                </Select>

              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                // style={{ marginTop: '-5px' }}
                label="Sprint"
                name="sprint"
              // rules={[
              //   {
              //     // required: true,
              //     message: 'Please Select Sprint',
              //     whitespace: true,
              //   },
              // ]}
              >
                <Select
                  showSearch='true'
                >
                  {sprintdata?.map((item, index) => {
                    return (
                      <Select.Option value={JSON.stringify({ _id: item._id, Name: item.Name })}>
                        {item.Name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <div style={{ textAlign: 'end', marginTop: '10px' }}>
              <Button
                // className="btn"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ marginRight: '10px' }}
                className="pms-same-btn"
              >
                Submit
              </Button>
              <Button
                className="pms-same-btn-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Add;
