import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Select,
  Checkbox,
  Table,
  Calendar,
  Space,
  DatePicker,
  Typography,
  Upload,
} from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import SelectEmpployee from "../../Common/SelectEmpployee";
import moment from "moment";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import PmsRangePicker from "./RangePicker";
const columns = [
  {
    title: "User",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },
  ,
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "Days",
    dataIndex: "Days",
    key: "Days",
  },
];

const data = [
  {
    key: "1",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "Meet chotaliya",
    Date: "1 oct",
    Days: "0.5 Day",
  },
  {
    key: "2",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "Jigar patel",
    Date: "25oct - 28oct",
    Days: "1 Day",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
  {
    key: "3",
    user: (
      <img src="https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg"></img>
    ),
    name: "pinkesh patel",
    Date: "10 jul",
    Days: "2 Days",
  },
];
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
// main component
const LeaveForm = (props) => {
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const showEmployee = props.showEmployee ? props.showEmployee : false;

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const [emergency, setEmergency] = useState(false);

  const [justifyActive, setJustifyActive] = useState("tab1");
  const [value, setValue] = useState();
  const [valueFirstDay, setValueFirstDay] = useState("First Half");
  const [valueSecondDay, setValueSecondDay] = useState("Second Half");
  const [employeeId, setEmployeeId] = useState([]);
  const [leaveDates, setLeaveDates] = useState();
  const [notifiedEmployee, setNotifiedEmployee] = useState([]);
  const [status, setStatus] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [flag, setFlag] = useState(false);

  //check days diff for more than 3 days leave
  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  function parseDate(str) {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  useEffect(() => {
    if (leaveDates) {
      const startDate = leaveDates?.[0].format("MM/DD/YYYY");
      const endDate = leaveDates?.[1].format("MM/DD/YYYY");
      let totalDays = datediff(parseDate(startDate), parseDate(endDate));
      if (leaveType == "Casual Leave") {
        if (totalDays >= 3) {
          // alert("Please Give Proper Reason")
          setFlag(false)
          warning()
        } else {
          setFlag(true)
        }
      } else {
        setFlag(true)
      }
    }
  }, [leaveDates, leaveType])


  const optionsWithDisabled = [
    { label: "First Half", value: "First Half" },
    { label: "Second Half", value: "Second Half" },
  ];
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  let history = useHistory();

  const onFinish = async (values) => {
   
    // if  (flag) {
      const startDate = leaveDates[0].format("YYYY-MM-DD");
      const endDate = leaveDates[1].format("YYYY-MM-DD");
      // console.log(startDate, endDate)

      if (showEmployee) {
        if (status == "") {
          alert("Please select status");

          return false;
        }
      }

      if (values) {
        const formData = new URLSearchParams();
        var date2 = new Date();
        let fomatedDate = moment(date2).format("MMM DD YYYY hh:mm a");

        formData.append("Email", values.email);
        formData.append("Leave_Date_to_Date", values.dateToDate);
        formData.append("StartDate", startDate);
        formData.append("EndDate", endDate);
        formData.append("Reason", values.reason);
        formData.append("Leave_half_from", valueFirstDay);
        formData.append("Leave_half_to", valueSecondDay);
        formData.append("Notified_Employee", notifiedEmployee);
        formData.append("Create_at", fomatedDate);
        formData.append("Emergency_leave", emergency);
        formData.append("Leave_type", leaveType)

        // formData.append('Employee_ID', userData[0]?._id)

        formData.append(
          "Employee_ID",
          showEmployee ? employeeId[0] : userData[0]?._id
        );

        formData.append("Status", showEmployee ? status : "Pending");
        // if(showEmployee){}

        AxiosInstance.post("auth/leave/add", formData)
          .then((res) => {
            if (res.data.status == 1) {
              toast.success("Leave applied Successfully");
              props.propData();
            }
          })
          .catch((err) => console.log(err, "err"));
      }
      // props.propData(true);
      if (showEmployee) {
        history.push("/leaves-report");
      } else {
        history.push("/leaves");
      }

      form.resetFields();
      setVisible(false);
    // } else {
    //   handleCancel()
    // }
  }
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeFirstDay = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio4 checked", value);
    setValueFirstDay(value);
  };
  const onChangeSecondDay = ({ target: { value } }: RadioChangeEvent) => {
    console.log("radio4 checked", value);
    setValueSecondDay(value);
  };

  const handleCancel = (errorInfo) => {
    form.resetFields();
    form1.resetFields();
    props.propData();
    setVisible(false);
  };
  const warning = () => {
    Modal.info({
      className: "leave-notice-msg",
      content: (
        <div>
          <p style={{ margin: 0 }}>According to HR rule you can not applied a leave for more than 3 days.</p>
          <p style={{ margin: 0, marginTop: 5 }}>If you want to applied for more than 3 days then please mention valid reason.</p>
        </div>
      ),
    });
  }

  return (
    <>
      <Button
        className="pms-same-btn leave-btn"
        type="primary"
        onClick={() => setVisible(true)}
      // style={{ marginRight: '6px', float: 'right' }}
      >
        Apply Leave
      </Button>

      <Modal
        title="Employee Leave Form"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        width={550}
        className="leave-form modal-back modal-title close-btn"
        footer={null}
      >
        {/* <MDBTabsContent>
            <MDBTabsPane show={justifyActive === 'tab1'}> */}
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          // name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="left-margin"
        >
          <Row className="eave-rows">
            {showEmployee && (
              <Col span={24} className="text-area">
                <Form.Item
                className="leave-rows"
                  label="Employee"
                  name="reason"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Reason!",
                    },
                  ]}
                >
                  <SelectEmpployee
                  className="leave-rows"
                    mode="single"
                    setEmployeeIds={(value) => setEmployeeId(value)}
                  />
                </Form.Item>
              </Col>
            )}
            <Col className="date-module leave-rows">
              <Form.Item
                className="date-input"
                name="emergency"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Checkbox onChange={(e) => setEmergency(e.target.checked)}>
                  Mark as an emergency (1 leave per month)
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="text-area leave-rows">
              <Form.Item
                label="Leave Type"
                name="Leave_type"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Leave Type!',
                    whitespace: true
                  },
                ]}
              >
                <Select
                  onChange={(e) => { setLeaveType(e) }}
                >
                  <Option value="Casual Leave">
                    Casual Leave
                  </Option>
                  <Option value="Sick Leave">
                    Sick Leave
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="new-date-leave date-module leave-rows">
              <Form.Item
                className="date-input"
                label="Leave Date"
                name="dateToDate"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Leave dates!",
                  },
                ]}
              >
                <RangePicker
                  format={"DD/MM/YYYY"}
                  onChange={setLeaveDates}
                  disabledDate={(current) => {
                    return (
                      !showEmployee &&
                      moment().add(+3, "days") >= current &&
                      !emergency
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="half-leave-section leave-rows">
              <Radio.Group
                options={optionsWithDisabled}
                onChange={onChangeFirstDay}
                value={valueFirstDay}
                optionType="button"
                buttonStyle="solid"
                className=""
              />{" "}
              To
              <Radio.Group
                options={optionsWithDisabled}
                onChange={onChangeSecondDay}
                value={valueSecondDay}
                optionType="button"
                buttonStyle="solid"
                className=""
              />
            </Col>
          </Row>

          <Row>
            <Col span={24} className="text-area leave-rows">
              <Form.Item
                label="Reason For Leave"
                name="reason"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Reason!",
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span={24} className="text-area leave-rows">
              <Form.Item
                label="Notify Employee"
                name="Notified_Employee"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: false,
                    message: "Please Enter Reason!",
                  },
                ]}
              >
                <SelectEmpployee
                  mode="multiple"
                  setEmployeeIds={(value) => setNotifiedEmployee(value)}
                />
              </Form.Item>
            </Col>{" "}
            {showEmployee && (
              <Col span={24} className="leave-status">
                <Form.Item
                  label="Status"
                  name="Status"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Status!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Status"
                    // style={{ paddingLeft: '10px', width: '70%' }}
                    className="select-dropdown leave-4"
                    onChange={(e) => {
                      setStatus(e);
                    }}
                  >
                    <Option value="Approved">Approved</Option>
                    <Option value="Conditional Approved">
                      Conditional Approved
                    </Option>
                    <Option value="Unapproved">Unapproved</Option>
                    <Option value="Rejected">Rejected</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row className="leave-rows">
            <Col span={24} className="colemwidth leave-rows">
              <Form.Item
                label="File Upload"
                name="file"
                style={{ alignItems: "center" }}
              >
                <Upload {...props}>
                  <Button style={{ width: "31.8rem" }} icon={<UploadOutlined />} className="pms-same-btn">
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {/* <div style={{ textAlign: 'end', marginRight: '60px' }}> */}
            <div style={{ textAlign: 'end', marginTop: '20px' }}>
              <Button
                type="primary"
                className="pms-same-btn mb-0"
                htmlType="submit"
                style={{ marginRight: "10px" }}
              >
                Request for Leave
              </Button>
              <Button onClick={handleCancel} className="pms-same-btn-cancel">Cancel</Button>
              {/* </div> */}
            </div>
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};

export default LeaveForm;
