import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard, MDBRow } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";

import axiosInstance from "../../service/AxiosInstance";
import { Button, DatePicker, Select } from "antd";
import moment from "moment";
import { AttendanceOption } from "../Options";
import FaceLogs from "./FaceLogs";

export default function MonthWiseAttendanceReport() {
  const [flag, Setflag] = useState(false);
  const [employees, Setemployees] = useState([]);
  const [loading, setLoading] = useState("");
  const [date, setDate] = useState(moment());
  const [datatable, setDatatable] = React.useState({});
  const [attendanceOption, setAttendanceOption] = useState("ALL");
  const [visible, setVisible] = useState(false);
  const [logs, setLogs] = useState(false);

  const UserData = localStorage.getItem("userdata")
    ? localStorage.getItem("userdata")
    : undefined;
  let Position_Id = UserData ? JSON.parse(UserData)[0].Position_id : undefined;
  useEffect(() => {
    let showResult = employees.filter((e) => {
      if (attendanceOption == "ABSENT") {
        return e.faceinOut == "" || e.faceinOut == undefined;
      }
      if (attendanceOption == "PRESENT") {
        return e.faceinOut !== undefined && e.faceinOut !== "";
      }
      return true;
    });

    setDatatable({
      columns: [
        {
          label: "Name",
          field: "name",
          width: 150,
          sort: "disabled",
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Email",
          field: "email",
          sort: "disabled",
          width: 270,
        },
        {
          label: "Department",
          field: "department",
          sort: "asc",
          width: 200,
        },
        {
          label: "Position",
          field: "position",

          width: 200,
        },
        {
          label: "Check IN",
          field: "faceinOut",

          width: 100,
        },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: showResult,
    });
  }, [employees, flag, attendanceOption]);
  const showDetails = (data) => {
    console.log(data);
    setLogs(data)
    showDrawer();
  };
  useEffect(() => {
    setLoading("Generating report....");
    axiosInstance
      .get("auth/report/FaceLoginMonthlyList?date=" + date.format("YYYY-MM-DD"))
      .then(async (result) => {
        setLoading("");
        if (result.data.data) {
          let employeesdeta = await result.data.data.map((item) => {
            let row = item.faceLogs;
            let lastRow = null;
            let faceOut = "";
            let logs = [];
            if (item.faceLogs.length > 0) {
              row = item.faceLogs[0];
              logs.push(row.check_in_time.split(" ")[1]);
              if (item.faceLogs.length > 1) {
                lastRow = item.faceLogs[item.faceLogs.length - 1];
                faceOut = lastRow.check_in_time;
                logs.push(lastRow.check_in_time.split(" ")[1]);
              }
            }

            let faceinOut = row.check_in_time;

            return {
              name: `${item.emp.First_name} ${item.emp.Middle_name} ${item.emp.Last_name}  (${item.emp.Employee_code})`,
              // name: (
              //   <Link style={{fontWeight:'500'}}
              //     to={{ pathname: `/employeeDetail/${item._id}` }}
              //   >{`${item.First_name} ${item.Middle_name} ${item.Last_name}`}</Link>
              // ),

              email: item.emp.Email,

              position: item.emp.Position_id.Position_name,
              department: item.emp.Role_id.Department_name,
              mobileNo: item.emp.Mobile,
              faceinOut: logs.join(" - "),
              logs: logs,

              action: (
                <>
                  <Button
                  className="pms-same-btn"
                    to={{ pathname: `/employeeDetail/${item._id}` }}
                    onClick={() => showDetails(item)}
                  >
                    View
                  </Button>
                </>
              ),
            };
          });
          Setemployees(employeesdeta);
        }
      });
  }, [flag, date]);
  const handleChanges = (e) => {
    setDate(e);
    console.log(e.format("YYYY-MM-DD"));
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <FaceLogs
        visible={visible}
        logs={logs}
        onClose={(value) => onClose(value)}
      />
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol size="md" className="col-example">
              <h3 className="tablHeader">MonthlyFaceAttendance</h3>
            </MDBCol>
          </div>
          <div className="tasklisttbl">
            <MDBRow className="">
              <MDBCol size="md-3">
                <label className="text-font-weight-600">Date</label>

                <DatePicker
                  // searchStyle={{paddingLeft:'10px', width:'60%'}}
                  onChange={(d) => handleChanges(d)}
                />
              </MDBCol>
              <MDBCol size="md-2">
                <label className="text-font-weight-600">Show</label>
                <Select
                  // style={{ width: '100%' }}
                  className="select-dropdown box-width"
                  value={attendanceOption}
                  onChange={(e) => setAttendanceOption(e)}
                  options={AttendanceOption}
                />
              </MDBCol>

              <MDBCol size="md-3">{loading}</MDBCol>
            </MDBRow>
            <hr />
            <MDBDataTableV5
              hover
              entriesOptions={[20, 25, 30]}
              entries={20}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>
        </MDBCard>
      </div>
    </>
  );
}

//
