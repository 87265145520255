import React, { useState,useEffect } from "react";
import {
  Card,
  Drawer,
  Badge,
  } from "antd";
import { MDBDataTableV5 } from "mdbreact";

const FaceLogs = (props) => {
  const { visible, logs } = props;
  const [datatable, setDatatable] = React.useState({});
  const color = false == "Approved" ? "green" : "gray";
  useEffect(() => {
    
    

    setDatatable({
      columns: [
        
        {
          label: "Date",
          field: "check_in_time",
          sort: "disabled",
          width: 270,
        },
       
      ],
      rows: logs.faceLogs,
    });
  }, [logs]);
  return (
    <>
      <Drawer
        title="Attandance logs"
        placement="right"
        onClose={props.onClose}
        visible={visible}
      >
        <Badge.Ribbon color={color}>
          <Card size="small" style={{ width: 300 }}
           title={
            logs?.emp?.First_name +
            " " +
            logs?.emp?.Last_name
          }
          
          ></Card>
        </Badge.Ribbon>
        <MDBDataTableV5
          hover
          entriesOptions={[20, 25, 30]}
          entries={20}
          pagesAmount={4}
          data={datatable}
          searchTop
          searchBottom={false}
        />
      </Drawer>
    </>
  );
};
export default FaceLogs;
