import { MDBDataTableV5, MDBCard } from 'mdbreact'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit'
import React, { useState, useEffect } from 'react'
import AxiosInstance from '../../service/AxiosInstance'
import moment from 'moment'
import {
  Dropdown,
  Menu,
  Form,
  Modal,
  Input,
  Button,
  Radio,
  TimePicker,
  Checkbox,
  Row,
  Col,
  Select,
} from 'antd'
import confirm from 'antd/lib/modal/confirm'
import PmsDatePicker from '../Me/LeaveModule/PmsDatePicker'
import ManualAttendanceLogs from './ManualAttendanceLogs'
import { findOne } from 'domutils'

const ManualAttendanceReport = () => {
  const [attendanceReport, setAttendanceReport] = useState([])
  const [datatable, setDatatable] = useState({})
  const [rejectComment, setRejectComment] = useState('')
  const [isRejectOpen, setIsRejectOpen] = useState(false)
  const [isApproveOpen, setIsApproveOpen] = useState(false)
  const [empId, setEmpId] = useState('')
  const [employee, setEmployee] = useState({})
  const [row, setRow] = useState({})
  const [rejectId, setRejectId] = useState('')
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [checkInValue, setCheckInValue] = useState(moment())
  const [checkOutValue, setCheckOutValue] = useState(moment())
  const [presentFirstHalf, setPresentFirstHalf] = useState(false)
  const [presentSecondHalf, setPresentSecondHalf] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [filteredDate, setFilteredDate] = useState()
  const [status, setStatus] = useState('All')
  const [result, setResult] = useState([])
  const [logs, setLogs] = useState([])
  const [employeeIds, setEmployeeIds] = useState([])
  const [form] = Form.useForm()
  const [leave, setLeave] = useState("")

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: 'Employee Name',
          field: 'name',
          width: 150,
          sort: 'disabled',
        },
        {
          label: 'Date',
          field: 'date',
          width: 200,
          sort: 'disabled',
        },

        {
          label: 'Type',
          field: 'type',
          width: 150,
          sort: 'disabled',
        },

        {
          label: 'Time',
          field: 'time',
          width: 270,
          sort: 'disabled',
        },
        {
          label: 'Reason',
          field: 'reason',
          width: 270,
          sort: 'disabled',
        },

        {
          label: 'Status',
          field: 'status',
          width: 270,
          sort: 'disabled',
        },
        {
          label: 'Comment',
          field: 'comment',
          width: 270,
          sort: 'disabled',
        },
        {
          label: 'Action',
          field: 'Action',
          sort: 'disabled',
          width: 100,
        },
      ],
      rows: filteredData,
    })
  }, [attendanceReport, filteredData])

  const generateRestult = () => {
    let filteredResult = result
    if (status != 'All') {
      filteredResult = result.filter((item) => item.status == status)
    }

    let list1 = filteredResult.map((item, index) => {
      return {
        name:
          item?.employee_id?.First_name +
          ' ' +
          item?.employee_id?.Middle_name +
          ' ' +
          item?.employee_id?.Last_name,
        date: item?.date ? moment(item?.date).format('DD/MM/YYYY') : '',
        type: item?.type,
        time: item?.time ? moment(item?.time).format('hh:mm A') : '',
        reason: item?.reason,
        comment: item?.status_comment ? item?.status_comment : '-',
        status: item?.status,
        Action: actions(item),
      }
    })

    if (filteredDate === undefined) {
      setAttendanceReport(list1)
      setFilteredData(list1)
    } else {
      return false
    }
  }

  useEffect(() => {
    generateRestult()
  }, [result, status])

  const getAllRequest = () => {
    AxiosInstance.get('auth/getAllMaunualAttendanceRequest').then((res) => {
      setResult(res.data.data)
    })
  }

  const getAttendanceRequest = (requestId) => {
    let urlencoded = new URLSearchParams()
    urlencoded.append('id', empId)

    AxiosInstance.post('auth/getManualAttendanceByEmpCode', urlencoded).then(
      (res) => {
        setEmployee(res.data.data)
        form.setFieldsValue({
          presentcount: res.data.data.present_count,
          presentFirstHalf: res.data.data.present_first_half,
          presentSecondHalf: res.data.data.present_second_half,
        })
        setPresentSecondHalf(res.data.data.present_second_half)
        setPresentFirstHalf(res.data.data.present_first_half)
        setCheckInValue(moment(res.data.data.check_in_time))
        setCheckOutValue(moment(res.data.data.check_out_time))
      },
    )
  }
  useEffect(() => {
    getAttendanceRequest()
  }, [empId])

  useEffect(() => {
    getAllRequest()
  }, [])
  console.log()
  function getAttendanceLogs(id) {
    AxiosInstance.get(
      `auth/attendance/logs?id=${id}`,
    ).then(async (res) => {
      // res.data.data

      setLogs(res.data.data)
      showDrawer()
      console.log(res.data, "4545");
    })
  }

  const approveStatus = () => {
    const urlencoded = new URLSearchParams()
    urlencoded.append('added_first_half', Boolean(presentFirstHalf))
    urlencoded.append('added_second_half', Boolean(presentSecondHalf))
    urlencoded.append('manual_id', employee._id)
    urlencoded.append('status', 'approved')
    urlencoded.append('employee_id', employee.Employee_id)
    urlencoded.append('attendance_id', row._id)
    urlencoded.append('attendance_type', row.type)
    urlencoded.append('manual_check_in', checkInValue)
    urlencoded.append('manual_check_out', checkOutValue)

    AxiosInstance.post('/auth/attendanceRequestStatusUpdate', urlencoded).then(
      (res) => {
        if (res.data.status == 1) {
          getAllRequest()
          setIsApproveOpen(false)
        }
      },
    )
  }

  const rejectStatus = () => {
    const urlencoded = new URLSearchParams()
    urlencoded.append('reject_id', rejectId)
    urlencoded.append('status_comment', rejectComment)
    urlencoded.append('status', 'rejected')

    AxiosInstance.post('/auth/attendanceRequestStatusUpdate', urlencoded).then(
      (res) => {
        if (res.data.status == 1) {
          setRejectComment('')
          form.resetFields()
          setIsRejectOpen(false)
          getAllRequest()
        }
      },
    )
  }

  const handleCancel = (errorInfo) => {
    if (!isApproveOpen) {
      setIsRejectOpen(false)
    }
    setIsApproveOpen(false)
  }

  const handleClick = (items, key) => {
    setLeave(items);
    if (key == 'approve') {
      setIsApproveOpen(true)
      setEmpId(items._id)
      setRow(items)
    } else if (key == 'reject') {
      setIsRejectOpen(true)
      setRejectId(items?._id)
    } else if (key == 'logs') {
      showDrawer()
      getAttendanceLogs(items._id)
    }
  }

  const actionMenu = (items, listAction) => (
    <Menu items={listAction} onClick={(e) => handleClick(items, e.key)} />
  )
  const actions = (items) => {
    let listAction = [
      {
        key: 'approve',
        label: 'Approve',
        items: items,
      },
      {
        key: 'reject',
        label: 'Reject',
        items: items,
      },
      {
        key: 'logs',
        label: 'Logs',
        items: items,
      },
    ]

    return (
      <>
        <Dropdown.Button
          overlay={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    )
  }

  const showDrawer = () => {
    setDrawerVisible(true)
  }

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const onDateChange = (time) => {
    const selectedDate = moment(time._d).format('DD/MM/YYYY')
    setFilteredDate(selectedDate)
    const filteredReport = attendanceReport.filter((val) => {
      if (val.date === moment(time._d).format('DD/MM/YYYY')) {
        return val
      }
    })
    setFilteredData(filteredReport)
    setCheckInValue(time)
  }
  const onChangeCheckOut = (time) => {
    setCheckOutValue(time)
  }

  return (
    <div className="demo">
      <ManualAttendanceLogs
        logs={logs}
        leave={leave}
        visible={drawerVisible}
        onClose={(value) => closeDrawer(value)}
      />

      <Modal
        title="Approve request modal"
        centered
        visible={isApproveOpen}
        onOk={() => {
          handleClick(false)
        }}
        onCancel={handleCancel}
        width={410}
        footer={null}
      >
        {isApproveOpen && (
          <Form form={form}>
            <Form.Item
              label="Check In Time"
              name="checkInTime"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please Select time",
            //   },
            // ]}
            >
              {' '}
              {checkInValue.format('HH:mm')}
              <TimePicker
                onChange={'Date'}
                // defaultValue={moment(
                //   moment(employee?.check_in_time).format("HH:mm"),
                //   "HH:mm"
                // )}
                defaultValue={checkInValue}
                value={checkInValue}
              />
            </Form.Item>
            <Form.Item
              label="Check Out Time"
              name="checkOutTime"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please Select time",
            //   },
            // ]}
            >
              {' '}
              {checkOutValue.format('HH:mm')}
              <TimePicker
                onChange={onChangeCheckOut}
                defaultValue={checkOutValue}
                value={checkOutValue}
              />
            </Form.Item>
            <Form.Item label="Present count" name="presentcount">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Present First Half" name="presentFirstHalf">
              <Checkbox
                checked={presentFirstHalf}
                onChange={(e) => setPresentFirstHalf(e.target.checked)}
              />
              {/* <Radio.Group>
                <Radio>True</Radio>
                <Radio>False</Radio>
              </Radio.Group> */}
            </Form.Item>
            <Form.Item label="Present Second Half" name="presentSecondHalf">
              <Checkbox
                checked={presentSecondHalf}
                onChange={(e) => setPresentSecondHalf(e.target.checked)}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              style={{ marginRight: '10px' }}
              onClick={() => approveStatus()}
              className="pms-same-btn"
            >
              Submit
            </Button>
          </Form>
        )}
      </Modal>

      <Modal
        title="Reject request modal"
        centered
        visible={isRejectOpen}
        onOk={() => setIsRejectOpen(false)}
        onCancel={handleCancel}
        width={410}
        footer={null}
      >
        <Form form={form}>
          <div style={{ marginBottom: '10px' }}>
            Comment<span style={{ color: 'red' }}>*</span>
          </div>
          <Form.Item
            name="status_comment"
            rules={[
              {
                required: true,
                message: 'Please Enter Description',
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              maxLength={250}
              showCount
              onChange={(e) => setRejectComment(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: 'end' }}>
              <Button onClick={() => rejectStatus()} className="pms-same-btn-cancel">
                Reject
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <MDBCard className="page-content">
        <div>
          <MDBCol
            // style={{ marginLeft: '7px' }}
            size="md"
            className="col-example"
          >
            {' '}
            <>
              <div className="leave-set-btn2">
                <h3 className="tablHeader">Manual Attendance Request Report</h3>
              </div>
            </>
          </MDBCol>
        </div>
        <hr />
        <Row className='create-project'>
          <Col span={6}>
            <Form.Item label="Date" name="date">
              <PmsDatePicker format={'DD/MM/YYYY'} onChange={onDateChange} />
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Select
              showSearch="true"
              placeholder="Select Status"
              value={status}
              onChange={(e) => setStatus(e)}
            >
              <Select.Option value="Pending">Pending</Select.Option>
              <Select.Option value="Approved">Approved</Select.Option>
              <Select.Option value="Rejected">Rejected</Select.Option>
            </Select>
          </Col>
        </Row>
        <div className="scrool-set">
          <MDBDataTableV5
            hover
            entriesOptions={[10, 20, 25]}
            entries={10}
            pagesAmount={4}
            data={datatable}
            searchTop
            searchBottom={false}
          />
        </div>
      </MDBCard>
    </div>
  )
}

export default ManualAttendanceReport
