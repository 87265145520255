import React, { useEffect, useState } from "react";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Form, Menu, Modal, Table } from "antd";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
  MDBDropdownToggle,
  MDBDropdownLink,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownMenu,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Permission from "../Permission";
import logo3 from "../../assets/images/logo3.png";
import { Button, Drawer, Radio, Space } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Checkinout from "../Checkinout/Checkinout";
import AxiosInstance from "../../service/AxiosInstance";
import moment from "moment";
import Sidebar from "./Sidebar";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Reprots", "sub3", <SettingOutlined />, [
    getItem(<Link to="/reportList">Task Report</Link>),
    getItem(<Link to="/leaves-report">Leave Report</Link>),
  ]),
];
const items2 = [
  getItem("Master", "sub3", <SettingOutlined />, [
    getItem(<Link to="/positions">Position</Link>),
    getItem(<Link to="/roles-permission">Roles Permission</Link>),
    getItem(<Link to="/department">Department</Link>),
    getItem(<Link to="/Holidays">Holidays</Link>),
  ]),
];

const onClick = (e) => { };

// const menu = (
//   <Menu
//     className="sidebar-dropdown"
//     items={[
//       {
//         key: "1",
//         label: <Link to="/positions">Position</Link>,
//       },
//       {
//         key: "2",
//         label: <Link to="/department">Department</Link>,
//       },
//       {
//         key: "3",
//         label: <Link to="/Holidays">Holidays</Link>,
//       },
//     ]}
//   />
// );

export default function Header(props) {


  let UserData = localStorage.getItem("userdata")
    ? localStorage.getItem("userdata")
    : undefined;
  let Position_Id = UserData ? JSON.parse(UserData)[0].Position_id : undefined;
  let history = useHistory();
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userdata");
    history.push("/login");
    window.location.reload();
  };
  const [visible, setVisible] = useState(false);
  const [leaveApporveStatus, setLeaveApproveStatus] = useState("Approved");
  const [leaveIssueStatus, setLeaveIssueStatus] = useState("Issue");

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [profilePic, setProfilePic] = useState("");
  const [leaveData, setLeaveData] = useState({});
  const [rolePermissions, setRolepermissions] = useState({});
  const [rolePermissions1, setRolepermissions1] = useState({});

  let user_data = JSON.parse(localStorage.getItem("userdata"));
  let Code = user_data?.[0]?.Employee_code;

  const getRolePermissionList = async () => {
    const data = new URLSearchParams();
    data.append("id", userData[0]?.Role_permission_id);
    await AxiosInstance.post("auth/permission/details", data).then((res) => {
      console.log(res.data, 14);

      if (res.data.status == 1) {
        setRolepermissions(res.data.data[0]);
      }
    });
  };

  console.log(
    rolePermissions.Employee,
    "rolePermissions",
    Object.values(rolePermissions?.Employee || {})
  );
  // console.log(Object.keys(rolePermissions.Employee).length === 0, 'rolePermissions1');
  // Object.values(rolePermissions?.Employee).includes('1')

  useEffect(() => {
    getRolePermissionList();
    // data()
  }, []);

  useEffect(() => {
    if (rolePermissions) {
      setRolepermissions1(rolePermissions);
    }
  }, [rolePermissions]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setProfilePic(userData[0]?.Profile_pic);
  }, []);
  // const onChange = (e) => {
  //   setPlacement(e.target.value);
  // };

  const getLeaveData = () => {
    if (Code) {
      const formData = new URLSearchParams();

      formData.append("Code", Code);
      formData.append("month", moment().month());

      AxiosInstance.post("getEmployeePresentDetailsByCode", formData).then(
        (res) => {
          if (res.data.status == 200) {
            if (res?.data?.data?.length) {
              setLeaveData(res.data.data);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    Data();
  }, [rolePermissions]);

  const Data = () => {
    return (
      <>
        {rolePermissions?.Employee !== undefined &&
          rolePermissions?.Employee !== null &&
          Object.values(rolePermissions?.Employee).includes("1") ? (
          <MDBNavbarItem>
            <Link to="/employees">
              <MDBNavbarLink>Employee</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        ) : (
          ""
        )}
      </>
    );
  };
  useEffect(() => {
    getLeaveData();
  }, [Code]);

  let date = moment(leaveData?.[0]?.Date).format("DD/MM/YYYY");
  const dataSource = [
    {
      key: "1",
      name: "Date",
      age: `${date}`,
    },
    {
      key: "1",
      name: "Name",
      age: `${leaveData?.[0]?.Name}`,
    },
    {
      key: "1",
      name: "Code",
      age: `${leaveData?.[0]?.Code}`,
    },
    {
      key: "1",
      name: "Working Days",
      age: `${leaveData?.[0]?.Working_days}`,
    },
    {
      key: "1",
      name: "Full Leave",
      age: `${leaveData?.[0]?.Full_leave}`,
    },
    {
      key: "1",
      name: "Half Leave",
      age: `${leaveData?.[0]?.Half_leave}`,
    },
    {
      key: "1",
      name: "Missed_punch",
      age: `${leaveData?.[0]?.Missed_punch}`,
    },
    {
      key: "1",
      name: "WFM",
      age: `${leaveData?.[0]?.WFM}`,
    },
    {
      key: "1",
      name: "Carry Forward",
      age: `${leaveData?.[0]?.Carry_forward}`,
    },

    {
      key: "1",
      name: "Total Days",
      age: `${leaveData?.[0]?.Total_days}`,
    },
    {
      key: "1",
      name: "Allow",
      age: `${leaveData?.[0]?.Allow}`,
    },
    {
      key: "1",
      name: "Grand_Total",
      age: `${leaveData?.[0]?.Grand_Total}`,
    },
    {
      key: "1",
      name: "Attendence Status",
      age: `${leaveData?.[0]?.Attendence_Status}`,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
  ];
  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
  };

  const UpdateStatus = (status) => {
    if (status == "Approved") {
      const formData = new URLSearchParams();
      formData.append("Code", Code);
      formData.append("month", moment().month());
      formData.append("Attendence_Status", "Approved");

      AxiosInstance.post("LeaveAttendenceStatusUpdate", formData).then(
        (res) => {
          // setLeaveAttendenceStatus(res.data.data[0].Attendence_Status)
        }
      );
    }
    if (status == "Issue") {
      const formData = new URLSearchParams();
      formData.append("Code", Code);
      formData.append("month", moment().month());
      formData.append("Attendence_Status", "Issue");

      AxiosInstance.post(
        "LeaveAttendenceStatusUpdate",
        formData
      ).then((res) => { });
    }
  };

  return (
    <>
      <MDBNavbar
        expand="lg"
        light
        bgColor="new-head top-header mobile-header-img"
      >
        {/* <MDBNavbar bgColor="dark" dark expand="md"> */}
        <a
          href="#!"
          className="logo-wrapper waves-effect logo-vh-set mobilemenu-logo"
        >
          <img alt="MDB React Logo" className="img-fluid" src={logo3} />
        </a>

        <MDBContainer fluid className="mob-main">
          {/* <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler> */}
          <MDBCollapse navbar show={showNavNoTogglerSecond}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 employee-set">
              {/* <MDBNavbarItem>
                <Link to="/dashboard">
                  {' '}
                  <MDBNavbarLink active aria-current="page" href="#">
                    Home
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>  */}
              {/* <MDBNavbarItem>
                <Link to="/projects">
                  <MDBNavbarLink>Project</MDBNavbarLink>
                </Link>
              </MDBNavbarItem>  */}
              {/* {1 ? ( */}
              {/* {permissions[0]?.All == '1' ? ( */}

              {
                // console.log(  , 340)
              }

              <>
                {/* {Object.keys(rolePermissions1?.Employee || {}) &&  Object.values(rolePermissions1?.Employee || {}).includes(1) ? (
                  <MDBNavbarItem>
                    <Link to="/employees">
                      <MDBNavbarLink>Employee</MDBNavbarLink>
                    </Link>
                  </MDBNavbarItem>
                  ) : (
                    ''
                  )} */}
                <Data />

                <MDBNavbarItem>
                  {rolePermissions.Report &&
                    Object.values(rolePermissions?.Report).includes("1") ? (
                    <MDBDropdown>
                      <MDBDropdownToggle tag="a" className="nav-link">
                        Reports
                      </MDBDropdownToggle>

                      <MDBDropdownMenu>
                        {rolePermissions?.Report?.Leave_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/leaves-report">
                              <MDBDropdownLink>Leave Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Ticket_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/tickets-report">
                              <MDBDropdownLink>Ticket Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Face_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/attendance">
                              <MDBDropdownLink>Face Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.Report?.Manual_Attendance_Request ==
                          "1" ? (
                          <MDBDropdownItem>
                            <Link to="/manualAttendanceRequestReport">
                              <MDBDropdownLink>
                                Manual Attendance Request
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Attendance_Summary == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/attendance-summary">
                              <MDBDropdownLink>
                                Attendance Summary
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Attendance_Summary == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/kra">
                              <MDBDropdownLink>
                                KRA
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  ) : (
                    ""
                  )}
                </MDBNavbarItem>

                <MDBNavbarItem>
                  {rolePermissions?.PM_Report &&
                    Object.values(rolePermissions?.PM_Report).includes("1") ? (
                    <MDBDropdown>
                      <MDBDropdownToggle tag="a" className="nav-link">
                        PM Reports
                      </MDBDropdownToggle>

                      <MDBDropdownMenu>
                        {rolePermissions?.PM_Report?.Employee_Tracker == "1" ? (
                          <MDBDropdownItem>
                            <MDBNavbarItem>
                              <Link to="/employee-tracker">
                                <MDBNavbarLink>Employee Tracker</MDBNavbarLink>
                              </Link>
                            </MDBNavbarItem>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Seven_Days_Working_plan ==
                          "1" ? (
                          <MDBDropdownItem>
                            <MDBNavbarItem>
                              <Link to="/employeeWorkingPlan">
                                <MDBNavbarLink>
                                  7 Days Working plan
                                </MDBNavbarLink>
                              </Link>
                            </MDBNavbarItem>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Milestone_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/milestone/report">
                              <MDBDropdownLink>
                                Milestone Report
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Project_Status_Report ==
                          "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/project-status">
                              <MDBDropdownLink>
                                Project Status Report
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Sprint_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/sprint/report">
                              <MDBDropdownLink>Sprint Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Task_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/reportList">
                              <MDBDropdownLink>Task Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Task_Timer_Report ==
                          "1" ? (
                          <MDBDropdownItem>
                            <Link to="/tasks/tasktimer/report">
                              <MDBDropdownLink>
                                Task Timer Report
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Dues == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/dueDashboard">
                              <MDBDropdownLink>Dues</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  ) : (
                    ""
                  )}
                </MDBNavbarItem>
                {/* ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}

                <MDBNavbarItem>
                  {rolePermissions?.Master &&
                    Object.values(rolePermissions?.Master).includes("1") ? (
                    <MDBDropdown>
                      <MDBDropdownToggle tag="a" className="nav-link">
                        Master
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        {rolePermissions?.Master?.Position == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/positions">
                              <MDBDropdownLink>Position</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        <MDBDropdownItem>
                          <Link to="/roles-permission">
                            <MDBDropdownLink>Roles Permission</MDBDropdownLink>
                          </Link>
                        </MDBDropdownItem>
                        {rolePermissions?.Master?.Department == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/department">
                              <MDBDropdownLink>Department</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Master?.Holidays == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/Holidays">
                              <MDBDropdownLink>Holidays</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Master?.Ticket_Department == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/ticket-department">
                              <MDBDropdownLink>
                                Ticket Department
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Master?.Location == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/location">
                              <MDBDropdownLink>Location</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.Master?.Vertical == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/vertical">
                              <MDBDropdownLink>Vertical</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {/* <MDBDropdownItem>
                          <Link to="/event-dashboard">
                            <MDBDropdownLink>Events</MDBDropdownLink>
                          </Link>
                        </MDBDropdownItem> */}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  ) : (
                    ""
                  )}
                </MDBNavbarItem>
              </>
            </MDBNavbarNav>
          </MDBCollapse>
          {/* 
          <Button className="header-leave-btn" onClick={() => setVisible(true)}>
            Attendance Confirmation
          </Button> */}

          <Modal
            title="Please confirm your leave?"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={handleCancel}
            width={700}
            footer={null}
          >
            <Table
              size="small"
              dataSource={dataSource}
              pagination={false}
              columns={columns}
              showHeader={false}
            />

            <Button
              className="pms-same-btn"
              type="success"
              onClick={() => {
                UpdateStatus("Approved");
                setVisible(false);
              }}
              style={{
                marginRight: "10px",
                marginTop: "20px",
                backgroundColor: "green",
                color: "white",
              }}
            >
              Confirm
            </Button>
            <Button
              className="pms-same-btn-cancel"
              type="danger"
              onClick={() => {
                UpdateStatus("Issue");
                setVisible(false);
              }}
              style={{
                marginRight: "10px",
                marginTop: "20px",
              }}
            >
              Issue
            </Button>
          </Modal>

          <Checkinout {...props} />
          <MDBNavbarItem className="nav-bar-user-name">
            <MDBDropdown>
              <MDBDropdownToggle tag="a" className="nav-link user-name">
                {userData[0].First_name} &nbsp;
                {userData[0].Last_name}
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem>
                  <Link to="/profile">
                    <MDBDropdownLink>Profile</MDBDropdownLink>
                  </Link>

                  <Link onClick={() => logout()}>
                    <MDBDropdownLink>Logout</MDBDropdownLink>
                  </Link>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>

          <MDBNavbarBrand href="#" className="profile">
            <img
              className="profile-set"
              src={
                profilePic
                  ? profilePic
                  : "https://joeschmoe.io/api/v1/male/random"
              }
            ></img>
          </MDBNavbarBrand>

          <button type="primary" className="mobile-menu" onClick={showDrawer}>
            <MenuOutlined />
          </button>
          <Drawer
            title="main-drawer"
            placement={"left"}
            closable={false}
            onClose={onClose}
            open={open}
            visible={open}
            key={placement}
            className="main-drawer"
          >
            <MDBListGroup className="list-group-flush list-group-item-1 drawer-list">
              <NavLink exact={true} to="/" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="" className="mr-3 menu-icon" />
                  Dashboard
                </MDBListGroupItem>
              </NavLink>

              <NavLink to="/projects" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="" className="mr-3 menu-icon " />
                  Project
                </MDBListGroupItem>
              </NavLink>

              <NavLink to="/taskList" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="" className="mr-3 menu-icon " />
                  Task
                </MDBListGroupItem>
              </NavLink>

              <NavLink to="/directory" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="" className="mr-3 menu-icon " />
                  Directory
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/leaves" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="" className="mr-3 menu-icon " />
                  Leaves
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/tickets" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="" className="mr-3 menu-icon " />
                  Tickets
                </MDBListGroupItem>
              </NavLink>

              {/* <NavLink to="/employees" activeClassName="activeClass">
                <MDBListGroupItem>
                  <MDBIcon icon="" className="mr-3 menu-icon " />
                  Employee
                </MDBListGroupItem>
              </NavLink> */}

              {/* 
              <Menu
                className="report-mobile"
                onClick={onClick}
                style={{
                  width: 256,
                }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={[""]}
                mode="inline"
                items={items}
              /> */}

              {/* <Menu
                className="report-mobile"
                onClick={onClick}
                style={{
                  width: 256,
                }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={[""]}
                mode="inline"
                items={items2}
              /> */}

              {/* <Dropdown overlay={menu} className="master-dropdown">
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Master
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown> */}
            </MDBListGroup>

          </Drawer>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
