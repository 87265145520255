import React, { useEffect, useState } from "react";
import { MDBDataTableV5, MDBCard } from "mdbreact";
import { Select } from "antd";
import { Menu, Dropdown, } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import LeaveForm from "./LeaveForm";
import moment from "moment";
import Permission from "../../Permission";
import { EllipseText } from "../../Common/Coomon";
import confirm from "antd/lib/modal/confirm";
import { Col, Row, Statistic } from "antd";
import { toast } from "react-toastify";

let ApiCall;
const { Option } = Select;



// import { useHistory,Link } from "react-router-dom";

// main component
const List = () => {
  let userData = JSON.parse(localStorage.getItem("userdata"));
  const Position = userData[0].Position;
  const [rows, setRows] = useState([]);
  const [totalLeave, setTotalLeave] = useState(0);
  const [datatable, setDatatable] = useState({});
  const permission = Permission();
  const [addLeave, setAddLeave] = useState(false);
  const [apiData, setapiData] = useState([]);
  const [leaveLogs, setLeaveLogs] = useState([]);
  const [leave, setLeave] = useState({});
  const [currentMonthTotal, setCurrentMonthTotalu] = useState(0);
  const [last30DaysTotal, setLast30DaysTotal] = useState(0);

  const reRenderFun = (input) => {
    getLeaves();
  };

  useEffect(() => {
    getLeaves();
  }, [apiData]);

  const leaveDates = (items) => {
    const dateArray = items.Leave_Date_to_Date.split(",");
    const newStartDate = new Date(dateArray[0]);
    const newEndDate = new Date(dateArray[1]);
    const halfStart = items.Leave_half_from;
    const halfTo = items.Leave_half_to;

    let appliedDate = "";
    let days = "";
    if (
      moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
      moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY") &&
      halfStart == "First Half" &&
      halfTo == "Second Half"
    ) {
      appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      days = "1 Day";
    } else {
      appliedDate =
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") +
        " - " +
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      if (
        moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ==
        moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY")
      ) {
        appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
        if (halfStart == "Second Half") {
          days = "0.5 Day (Second Half)";
        } else {
          days = "0.5 Day (First Half)";
        }
      } else {
        let daysLeft =
          moment(newEndDate, "DD/MM/YYYY").diff(
            moment(newStartDate, "DD/MM/YYYY"),
            "days"
          ) + 1;
        if (halfStart == "First Half" && halfTo == "Second Half") {
          days = daysLeft + " Days";
        } else {
          if (halfStart == "Second Half") {
            daysLeft -= 0.5;
          }
          if (halfTo == "First Half") {
            daysLeft -= 0.5;
          }
          days = daysLeft + " Days (" + halfStart + " - " + halfTo + ")";
        }
      }
    }

    return (
      <>
        {appliedDate} <span className="info">{days}</span>{" "}
      </>
    );
  };
  function getLeaves() {
    AxiosInstance.get("auth/leaves").then(async (res) => {
      // res.data.data
      if (res.data.status == 1) {
        let data = await res.data.data.map((items) => {
          const dateArray = items.Leave_Date_to_Date.split(",");
          const newStartDate = new Date(dateArray[0]);
          const newEndDate = new Date(dateArray[1]);
          const creatdAt = (
            <p>{moment(items?.Create_at).format("DD/MM/YYYY")}</p>
          );
          const halfStart =
            items.Leave_half_from == "Second Half" ? "(First Half)" : "";
          const halfTo =
            items.Leave_half_to == "Second Half" ? "(First Half)" : "";
          //   items.Create_at = creatdAt;
          items.LeaveStartDate = leaveDates(items);
          items.status = (
            <p>
              {items.Status}
              <span className="info">
                {items.Action_by &&
                  " By " +
                  items?.Action_by?.First_name +
                  " " +
                  items?.Action_by?.Middle_name +
                  " " +
                  items?.Action_by?.Last_name}
              </span>
            </p>
          );
          items.action_on = items.Action_on
            ? moment(items?.Action_on).format("MMM DD, YYYY hh:mm a")
            : "-";
          items.leave_type = (
            <p>
              {items.Leave_type}{" "}
              <span className="info">
                Requested on{" "}
                {moment(items?.Create_at).format("MMM DD, YYYY HH:mm a")}
              </span>
            </p>
          );
          items.action = actions(items);
          items.ReasonElement = <EllipseText text={items.Reason} />;

          return items;
        });
        setRows(data);

      }
    });
  }

  const LeaveCount = async () => {
    AxiosInstance.get("auth/leave/leave-count").then((res) => {
      setLast30DaysTotal(res.data.data.LeavesinLast30Days);
      setCurrentMonthTotalu(res.data.data.LeavesInCurrentMonth);
      setTotalLeave(res.data.data.LeaveTotalDays);
    });
  };

  useEffect(() => {
    LeaveCount();
    setDatatable({
      columns: [
        {
          label: "Leave Dates",
          field: "LeaveStartDate",
          width: 200,
          sort: "disabled",
        },

        {
          label: "Leave Type ",
          field: "leave_type",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Requested By",
          field: "Created_by",
          sort: "disabled",
          width: 100,
        },

        {
          label: "Action Taken On",
          field: "action_on",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Leave Note",
          field: "ReasonElement",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: rows,
    });
  }, [rows]);

  const approve = (items, status) => {
    const formData = new URLSearchParams();

    formData.append("Status", status);
    formData.append("id", items._id);

    AxiosInstance.post("auth/leave/action", formData)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(`Leaved ${status} Successfull`);
          getLeaves();
        }
      })
      .catch((err) => console.log(err, "err"));
  };
  const handleClick = (items, status) => {
    setLeave(items);

    if (status == "cancel") {
      confirm({
        title: "Are you sure Cancel this leave?",

        content: items?.Reason,
        okText: "Yes",
        okType: "danger",

        cancelText: "No",

        onOk() {
          approve(items, "Cancelled");
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      getLeavesLog(items._id);
    }
  };
  function getLeavesLog(id) {
    AxiosInstance.get(`auth/leaves/leaveLogs?id=${id}`).then(async (res) => {
      // res.data.data
      console.log(res);
      setLeaveLogs(res.data.data);
      showDrawer();
    });
  }
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const actionMenu = (items, listAction) => (
    <Menu onClick={(e) => handleClick(items, e.key)} items={listAction} />
  );
  const actions = (items) => {
    let listAction = [
      {
        key: "logs",
        label: "Logs",
        items: items,
      },
    ];
    if (items.Status !== "Cancelled") {
      listAction.push({
        key: "cancel",
        label: "Cancel",
        items: items,
      });
    }
    return (
      <>
        <Dropdown.Button
          overlay={actionMenu(items, listAction)}
        ></Dropdown.Button>
      </>
    );
  };




  return (
    <>
      {/* <ToastContainer /> */}
      {/* {leave._id && (
        <LeaveLogs
          leave={leave}
          visible={visible}
          leaveLogs={leaveLogs}
          onClose={(value) => onClose(value)}
        />
      )} */}

      <div>
        <MDBCard className="page-content">
          <div className="listdiv">
            <div className="list-head">

              <div className="total-leave-details">
                <Row>
                  <Col className="leave-counter-no">
                    <Statistic title="This Month" value={currentMonthTotal} />
                  </Col>
                  <Col className="leave-counter-no">
                    <Statistic title="Last 30days" value={last30DaysTotal} />
                  </Col>
                  <Col className="leave-counter-no">
                    <Statistic title="Total Leaves" value={totalLeave} />
                  </Col>
                  <Col className="leave-counter-no">
                    <Statistic title="Carry Forward Leave" /* value={totalLeave} */ />
                  </Col>
                </Row>
              </div>
              <div className="qtr_leave">
                <div className="qtr_leave">
                  <p>QTR Leave : </p>

                  <Select
                    showSearch
                    style={{
                      width: 130,
                    }}
                    placeholder="Select Quarter"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={[
                      {
                        value: '1',
                        label: 'Quarter-1',
                      },
                      {
                        value: '2',
                        label: 'Quarter-2',
                      },
                      {
                        value: '3',
                        label: 'Quarter-3',
                      },
                      {
                        value: '4',
                        label: 'Quarter-4',
                      }
                    ]}
                  />
                </div>
                <LeaveForm propData={reRenderFun} />
              </div>
            </div>
          </div>
          <hr />
          <div className="scrool-set mobile-scroll">
            <MDBDataTableV5
              hover
              entriesOptions={[10, 20, 30]}
              entries={10}
              pagesAmount={4}
              data={datatable}
              searchBottom={false}
              className="leave-tbl"
            />
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default List;
