import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AxiosInstance from "../../../service/AxiosInstance";

import { MDBDataTableV5, MDBCard } from "mdbreact";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import moment from "moment";

const SprintReport = () => {
  const [sprintList, setSprintList] = useState([]);
  const [datatable, setDatatable] = useState({});

  const getMilestoneList = () => {
    AxiosInstance.get(`sprint/report`).then((res) => {
      let list1 = res.data.data.map((item, index) => {
        return {
          icon: index + 1,
          Name: item?.Name,
          Description: item?.Description,
          Project_name: item?.Project_id?.Project_name,
          Milestone: item.Milestone ? item.Milestone.Name : null,
          StartDate: item?.StartDate
            ? moment(item?.StartDate).format("DD/MM/YYYY")
            : "",
          DueDate: item?.DueDate
            ? moment(item?.DueDate).format("DD/MM/YYYY")
            : "",
          Scope_type: item?.Scope_type,
          Status: item?.Status,
        };
      });

      setSprintList(list1);
    });
  };

  

  useEffect(() => {
    getMilestoneList();
  }, []);

  useEffect(() => {
    setDatatable({
      columns: [
        {
          label: "No",
          field: "icon",
          sort: "disabled",
          width: 10,
        },
        {
          label: "Project Name",
          field: "Project_name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Milestone Name",
          field: "Milestone",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Name",
          field: "Name",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Description",
          field: "Description",
          width: 150,
          sort: "disabled",
        },
        {
          label: "Start Date",
          field: "StartDate",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Due Date",
          field: "DueDate",
          width: 270,
          sort: "disabled",
        },
        {
          label: "Scope type",
          field: "Scope_type",
          width: 100,
          sort: "disabled",
        },

        {
          label: "Status",
          field: "Status",
          width: 200,
          sort: "disabled",
        },
      ],
      rows: sprintList,
    });
  }, [sprintList]);

  return (
    <>
      <div className="demo">
        <MDBCard className="page-content">
          <div className="create-project">
            <MDBCol
              size="md"
              className="col-example"
            >
              {" "}
              <>
                <div className="leave-set-btn2">
                  <h3 className="tablHeader">Sprint Report</h3>

                </div>
              </>

            </MDBCol>
          </div>
          <div className="scrool-set">
            <MDBDataTableV5
              hover
              entriesOptions={[10, 20, 25]}
              entries={10}
              pagesAmount={4}
              data={datatable}
              searchTop
              searchBottom={false}
            />
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default SprintReport;
