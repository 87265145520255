import React, { useState } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBSpinner } from "mdb-react-ui-kit";
import Header from "./Header";
import { Switch, Route, Redirect } from "react-router-dom";
import Content from "./Content";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import "./DefaultLayout.css";
import { Spin } from "antd";
import { Routes } from "../../Routes/routes";
import { Loading } from "../Common/Loader";
import ReactLoader from "../Common/ReactLoader";

const loading = () => <ReactLoader/>;

const DefaultLayout = () => {
  let isLogedIn = localStorage.getItem("token");
  const [checkInCheckOut, setcheckInCheckOut] = useState(false);

  const [darkMode, setDarkMode] = useState(false);
  const handleModeChange = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.body.classList.add("darkmode-modal");
    } else {
      document.body.classList.remove("darkmode-modal");
    }
  };

  return (
    <div>
      {isLogedIn ? (
        <div className={`flexible-content ${darkMode ? "darkmode" : ""}`}>
          <Sidebar></Sidebar>
          <main id="content">
            <Header
              checkInCheckOut={checkInCheckOut}
              updateMode={() => handleModeChange()}
            />
            {/* <MDBContainer className=""> */}
            <React.Suspense fallback={loading()}>
              <Switch>
                {Routes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                          <route.component
                            {...props}
                            setcheckInCheckOut={(v) => {
                              setcheckInCheckOut(v);
                            }}
                          />
                        )}
                      />
                    )
                  );
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </React.Suspense>
          </main>
          <Footer />
        </div>
      ) : (
        <Redirect from="/" to="/login" />
      )}
    </div>
  );
};

export default DefaultLayout;
