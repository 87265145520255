import React from 'react';
import { Form, Input, Button } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBCardBody, MDBCardTitle } from 'mdb-react-ui-kit';
import AxiosInstance from '../../service/AxiosInstance';

// main component
const ForgotPass = () => {
  const [form] = Form.useForm();

  const notify = async () => {
    toast.success("If we found an account associated with that email address, we've sent password reset instructions to the primary email address on the account.", {

      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const onFinish = (values) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('Email', values.Email);
    AxiosInstance.post('/forgot-password', urlencoded).then(
      async (result) => {
        await notify();
        // changeflag.setflag(!changeflag.flag);
      }
    );
    console.log('Success:', values);

    // form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className="forgot-pw-modal">
      <div>
        <ToastContainer />
        <div>
          <MDBCardTitle className="">
            {/* <h5>Forgot Password</h5> */}
            Please enter your work email address
          </MDBCardTitle>
          <Form
            name="basic"
            labelCol={{
              span: 24,
            }}
            form={form}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="Email"
              rules={[
                {
                  required: true,
                  message: 'Please Enter your Email',
                },
              ]}
            >
              <Input
                placeholder="Enter your Email"
                className="fildewidth"
              />
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: '15px' }}
                className="pms-same-btn-cancel"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* </MDBCard> */}
      </div>
    </div>
  );
};

export default ForgotPass;
