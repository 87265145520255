import React from "react";
import Clock from "../../assets/images/clock.svg";
import AxiosInstance from "../../service/AxiosInstance";
import react, { useState, useEffect } from "react";
import moment from "moment";

const Checkinout = ({ checkInCheckOut }) => {
  const [isCheck, setisCheck] = useState(true);
  const [checkInData, setCheckInData] = useState(null);
  const [attendance, setAttendance] = useState([]);

  let userData = JSON.parse(localStorage.getItem("userdata"));
  const getOnCheck = localStorage.getItem("onCheck");
  const CheckinFun = () => {
    AxiosInstance.get("auth/report").then(async (res) => {
      
      if (res.data.status == 1) {
        setCheckInData(res.data.data[0] ?? null);
        setisCheck(false);
      } else {
        setisCheck(true);
      }
    });
  };

  const faceCheckInFun = () => {
    AxiosInstance.get("auth/facelog/last").then(async (res) => {
      let response = res.data.data.at(-1);
      if (response.date == new Date().toISOString().slice(0, 10)) {
        setAttendance(response);
      }
    });
  };

  useEffect(() => {
    faceCheckInFun();
    CheckinFun();
  }, [checkInCheckOut]);

  return (
    <div>
      <div className="head-check">
        <div className="clock space-check">
          <img className="head-clock" src={Clock} />
        </div>
        <div className="face-check space-check">
          <div className="head-face-in head-face">
            <p style={{ width: "90px" }}>Face Check in :</p>
            <div>
              {" "}
              {attendance?.checkIn
                ? attendance?.checkIn.split(" ")[1]
                : "Absent"}
            </div>
          </div>
          <div className="head-face-out head-face">
            <p style={{ width: "90px" }}>Face Check out :</p>
            <div>
              {" "}
              {attendance?.checkOut ? attendance?.checkOut.split(" ")[1] : ""}
            </div>
          </div>
        </div>
        <div className="only-check space-check">
          <div className="head-face-in head-face">
            <p style={{ width: "63px" }}>Check in :</p>
            <div>
              {" "}
              {checkInData?.Check_In ? checkInData?.Check_In.split(" ")[1] : ""}
            </div>
          </div>
          <div className="head-face-out head-face">
            <p style={{ width: "63px" }}>Check out :</p>
            <div>
              {" "}
              {checkInData?.Check_Out
                ? checkInData?.Check_Out.split(" ")[1]
                : ""}
            </div>
          </div>
        </div>
        <div className="space-check working-hour">
          <p style={{margin:"0"}}>
          Working Hours
          </p>
          <p style={{margin:"0"}}>
          00 : 00 : 00
          </p>
        </div>
      </div>
    </div>
  );
};

export default Checkinout;
